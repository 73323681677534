/* tslint:disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AmountHumanReadable: any;
  BigDecimal: string;
  BigInt: string;
  Bytes: string;
  Date: any;
  GqlBigNumber: any;
  JSON: any;
}

export interface AddPoolsManualInput {
  addresses: Array<InputMaybe<Scalars['String']>>;
  chainId: Scalars['Int'];
}

export interface AddStakersInput {
  addresses: Array<Scalars['String']>;
  chainId: Scalars['Int'];
}

export interface AddressToV2PoolBalanceMap {
  __typename: 'AddressToV2PoolBalanceMap';
  account: Scalars['String'];
  balances: Array<ContractBalanceMap>;
  totalValueUSD: Scalars['Float'];
}

export interface BurnV2 {
  __typename: 'BurnV2';
  amount0?: Maybe<Scalars['String']>;
  amount1?: Maybe<Scalars['String']>;
  amountUSD?: Maybe<Scalars['String']>;
  feeLiquidity?: Maybe<Scalars['String']>;
  feeTo?: Maybe<Scalars['Bytes']>;
  id: Scalars['ID'];
  liquidity: Scalars['String'];
  logIndex?: Maybe<Scalars['BigInt']>;
  needsComplete: Scalars['Boolean'];
  pair: GqlPoolV2;
  sender?: Maybe<Scalars['Bytes']>;
  timestamp: Scalars['BigInt'];
  to?: Maybe<Scalars['Bytes']>;
  tx: Scalars['String'];
}

export interface BurnV3 {
  __typename: 'BurnV3';
  amount: Scalars['BigInt'];
  amount0: Scalars['String'];
  amount1: Scalars['String'];
  amountUSD?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  origin: Scalars['Bytes'];
  owner?: Maybe<Scalars['Bytes']>;
  pool: GqlV3Pool;
  tickLower: Scalars['String'];
  tickUpper: Scalars['String'];
  timestamp: Scalars['BigInt'];
  tx: Scalars['String'];
}

export interface ChainIdInput {
  chainId: Scalars['Int'];
}

export type CommonPoolBase = GqlPoolV2 | GqlV3Pool;

export interface ContractBalanceMap {
  __typename: 'ContractBalanceMap';
  balance: Scalars['String'];
  usdValue?: Maybe<Scalars['Float']>;
  v2Pool: GqlPoolV2;
}

export interface GetNftPoolArgs {
  address?: InputMaybe<Scalars['String']>;
  lpToken?: InputMaybe<Scalars['String']>;
}

export interface GetNftPoolsArgs {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GetNftPoolsWhereInput>;
}

export interface GetNftPoolsWhereInput {
  category?: InputMaybe<Array<InputMaybe<GqlPoolFilterCategory>>>;
}

export type GetV2TokenAnalyticsOrderBy = 'dailyVolumeUSD';

export interface GqlNftPositionInfoBase {
  __typename: 'GqlNFTPositionInfoBase';
  nftPool: NftPool;
  tokenId: Scalars['String'];
}

export interface GqlPool {
  address: Scalars['String'];
  aprItems: Array<Maybe<GqlPoolAprItem>>;
  blockNumber: Scalars['Int'];
  categories: Array<Maybe<GqlPoolCategory>>;
  chainId: Scalars['Int'];
  createTime: Scalars['Int'];
  decimals?: Maybe<Scalars['Int']>;
  dynamicData?: Maybe<GqlPoolDynamicDataBase>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  swapFee: Scalars['Float'];
  symbol?: Maybe<Scalars['String']>;
  token0: GqlToken;
  token1: GqlToken;
  type: GqlPoolType;
}

export interface GqlPoolApr {
  __typename: 'GqlPoolApr';
  hasRewardApr: Scalars['Boolean'];
  items: Array<GqlPoolAprItem>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  nativeRewardApr: Scalars['Float'];
  swapApr: Scalars['Float'];
  thirdPartyApr: Scalars['Float'];
  total: Scalars['Float'];
}

export interface GqlPoolAprItem {
  __typename: 'GqlPoolAprItem';
  apr: Scalars['Float'];
  id: Scalars['String'];
  poolId: Scalars['String'];
  range?: Maybe<GqlPoolAprRange>;
  title: Scalars['String'];
  type: Scalars['String'];
}

export interface GqlPoolAprRange {
  __typename: 'GqlPoolAprRange';
  aprItem: GqlPoolAprItem;
  id: Scalars['String'];
  max: Scalars['Float'];
  min: Scalars['Float'];
}

export interface GqlPoolCategory {
  __typename: 'GqlPoolCategory';
  category: Scalars['String'];
  id: Scalars['String'];
}

export interface GqlPoolDynamicDataBase {
  fees24h: Scalars['Float'];
  lpPrice: Scalars['String'];
  reserves0: Scalars['String'];
  reserves1: Scalars['String'];
  totalLiquidity: Scalars['Float'];
  volume24h: Scalars['Float'];
}

export interface GqlPoolFilter {
  categoryIn?: InputMaybe<Array<GqlPoolFilterCategory>>;
  categoryNotIn?: InputMaybe<Array<GqlPoolFilterCategory>>;
  filterIn?: InputMaybe<Array<Scalars['String']>>;
  filterNotIn?: InputMaybe<Array<Scalars['String']>>;
  idIn?: InputMaybe<Array<Scalars['String']>>;
  idNotIn?: InputMaybe<Array<Scalars['String']>>;
  tokensIn?: InputMaybe<Array<Scalars['String']>>;
  tokensNotIn?: InputMaybe<Array<Scalars['String']>>;
}

export type GqlPoolFilterCategory = 'BLACK_LISTED' | 'COMMUNITY' | 'INCENTIVIZED';

export interface GqlPoolFilterDefinition {
  __typename: 'GqlPoolFilterDefinition';
  id: Scalars['ID'];
  title: Scalars['String'];
}

export type GqlPoolOrderBy = 'apr' | 'fees24h' | 'totalLiquidity' | 'totalShares' | 'volume24h';

export type GqlPoolOrderDirection = 'asc' | 'desc';

export interface GqlPoolSnapshot {
  __typename: 'GqlPoolSnapshot';
  fees24h: Scalars['String'];
  holdersCount?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  poolId: Scalars['String'];
  sharePrice: Scalars['String'];
  swapsCount?: Maybe<Scalars['String']>;
  timestamp: Scalars['Int'];
  totalLiquidity: Scalars['String'];
  totalShares: Scalars['String'];
  totalSwapFee?: Maybe<Scalars['String']>;
  totalSwapVolume?: Maybe<Scalars['String']>;
  volume24h: Scalars['String'];
}

export type GqlPoolSnapshotDataRange =
  | 'ALL_TIME'
  | 'NINETY_DAYS'
  | 'ONE_DAY'
  | 'ONE_HUNDRED_EIGHTY_DAYS'
  | 'ONE_YEAR'
  | 'THIRTY_DAYS';

export interface GqlPoolSwap {
  amount0In: Scalars['String'];
  amount0Out: Scalars['String'];
  amount1In: Scalars['String'];
  amount1Out: Scalars['String'];
  blockNumber: Scalars['Int'];
  from: Scalars['String'];
  id: Scalars['Int'];
  pool: GqlPool;
  sender: Scalars['String'];
  timestamp: Scalars['Int'];
  to: Scalars['String'];
  tokenAmountIn: Scalars['String'];
  tokenAmountOut: Scalars['String'];
  tokenIn: Scalars['String'];
  tokenInSym: Scalars['String'];
  tokenOut: Scalars['String'];
  tokenOutSym: Scalars['String'];
}

export interface GqlPoolSwapFilter {
  poolIdIn?: InputMaybe<Array<Scalars['String']>>;
  tokenInIn?: InputMaybe<Array<Scalars['String']>>;
  tokenOutIn?: InputMaybe<Array<Scalars['String']>>;
}

export type GqlPoolType = 'V2' | 'V3';

export interface GqlPoolV2 extends GqlPool {
  __typename: 'GqlPoolV2';
  address: Scalars['String'];
  aprItems: Array<Maybe<GqlPoolAprItem>>;
  blockNumber: Scalars['Int'];
  categories: Array<Maybe<GqlPoolCategory>>;
  chainId: Scalars['Int'];
  createTime: Scalars['Int'];
  decimals?: Maybe<Scalars['Int']>;
  dynamicData?: Maybe<GqlV2PoolDynamicData>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nftPool?: Maybe<NftPool>;
  swapFee: Scalars['Float'];
  symbol?: Maybe<Scalars['String']>;
  token0: GqlToken;
  token1: GqlToken;
  type: GqlPoolType;
}

export interface GqlStaker {
  __typename: 'GqlStaker';
  address: Scalars['String'];
  apr: Scalars['Float'];
  chainId: Scalars['Int'];
  depositFee: Scalars['Float'];
  depositToken: GqlToken;
  id: Scalars['String'];
  rewardPerSecond: Scalars['String'];
  rewardToken: GqlToken;
  totalDepositAmount: Scalars['Float'];
  totalDepositValueUSD: Scalars['Float'];
  userData?: Maybe<UserStakingInfo>;
}

export interface GqlToken extends GqlTokenBase {
  __typename: 'GqlToken';
  address: Scalars['String'];
  chainId: Scalars['Int'];
  coingeckoContractAddress?: Maybe<Scalars['String']>;
  coingeckoPlatformId?: Maybe<Scalars['String']>;
  coingeckoTokenId?: Maybe<Scalars['String']>;
  currentPrice?: Maybe<GqlTokenCurrentPrice>;
  decimals: Scalars['Int'];
  dexscreenPairAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isQuoteToken: Scalars['Boolean'];
  isStableCoin: Scalars['Boolean'];
  logoURI?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  symbol: Scalars['String'];
  tradeable: Scalars['Boolean'];
  useDexscreener?: Maybe<Scalars['Boolean']>;
  usePoolPricing?: Maybe<Scalars['Boolean']>;
}

export interface GqlTokenAmountHumanReadable {
  address: Scalars['String'];
  amount: Scalars['AmountHumanReadable'];
}

export interface GqlTokenBase {
  address: Scalars['String'];
  chainId: Scalars['Int'];
  decimals: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isQuoteToken: Scalars['Boolean'];
  isStableCoin: Scalars['Boolean'];
  logoURI?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  symbol: Scalars['String'];
  tradeable: Scalars['Boolean'];
}

export type GqlTokenChartDataRange =
  | 'ALL_TIME'
  | 'NINETY_DAYS'
  | 'ONE_DAY'
  | 'ONE_HUNDRED_EIGHTY_DAYS'
  | 'ONE_YEAR'
  | 'SEVEN_DAY'
  | 'THIRTY_DAYS';

export interface GqlTokenCurrentPrice {
  __typename: 'GqlTokenCurrentPrice';
  price?: Maybe<Scalars['Float']>;
}

export interface GqlTokenDayData {
  __typename: 'GqlTokenDayData';
  dailyTxns: Scalars['Int'];
  dailyVolumeETH: Scalars['String'];
  dailyVolumeToken: Scalars['String'];
  dailyVolumeUSD: Scalars['String'];
  date: Scalars['Int'];
  dateLabel: Scalars['String'];
  feesUSD24hr: Scalars['Float'];
  id: Scalars['ID'];
  priceUSD: Scalars['String'];
  token: GqlToken;
  totalLiquidityETH: Scalars['String'];
  totalLiquidityToken: Scalars['String'];
  totalLiquidityUSD: Scalars['String'];
}

export interface GqlTokenPrice {
  __typename: 'GqlTokenPrice';
  price: Scalars['Float'];
  token: GqlToken;
}

export interface GqlTokenPriceChartDataItem {
  __typename: 'GqlTokenPriceChartDataItem';
  close: Scalars['Float'];
  high: Scalars['Float'];
  id: Scalars['ID'];
  low: Scalars['Float'];
  open: Scalars['Float'];
  price: Scalars['AmountHumanReadable'];
  timestamp: Scalars['Int'];
}

export type GqlTradeType = 'EXACT_INPUT' | 'EXACT_OUTPUT';

export interface GqlUser {
  __typename: 'GqlUser';
  address: Scalars['String'];
  chainId: Scalars['Int'];
  id: Scalars['ID'];
  nftPositions: Array<Maybe<NftPoolPosition>>;
}

export interface GqlV2PoolDynamicData extends GqlPoolDynamicDataBase {
  __typename: 'GqlV2PoolDynamicData';
  apr: GqlPoolApr;
  fees24h: Scalars['Float'];
  fees24hAth: Scalars['BigDecimal'];
  fees24hAthTimestamp: Scalars['Int'];
  fees24hAtl: Scalars['BigDecimal'];
  fees24hAtlTimestamp: Scalars['Int'];
  fees48h: Scalars['BigDecimal'];
  holdersCount: Scalars['BigInt'];
  lifetimeSwapFees: Scalars['BigDecimal'];
  lifetimeVolume: Scalars['BigDecimal'];
  lpPrice: Scalars['String'];
  poolId: Scalars['ID'];
  reserves0: Scalars['String'];
  reserves1: Scalars['String'];
  sharePriceAth: Scalars['BigDecimal'];
  sharePriceAthTimestamp: Scalars['Int'];
  sharePriceAtl: Scalars['BigDecimal'];
  sharePriceAtlTimestamp: Scalars['Int'];
  swapsCount: Scalars['BigInt'];
  totalLiquidity: Scalars['Float'];
  totalLiquidity24hAgo: Scalars['BigDecimal'];
  totalLiquidityAth: Scalars['BigDecimal'];
  totalLiquidityAthTimestamp: Scalars['Int'];
  totalLiquidityAtl: Scalars['BigDecimal'];
  totalLiquidityAtlTimestamp: Scalars['Int'];
  totalShares: Scalars['String'];
  totalShares24hAgo: Scalars['BigDecimal'];
  volume24h: Scalars['Float'];
  volume24hAth: Scalars['BigDecimal'];
  volume24hAthTimestamp: Scalars['Int'];
  volume24hAtl: Scalars['BigDecimal'];
  volume24hAtlTimestamp: Scalars['Int'];
  volume48h: Scalars['BigDecimal'];
}

export interface GqlV2PoolSwap extends GqlPoolSwap {
  __typename: 'GqlV2PoolSwap';
  amount0In: Scalars['String'];
  amount0Out: Scalars['String'];
  amount1In: Scalars['String'];
  amount1Out: Scalars['String'];
  amountUSD: Scalars['Float'];
  blockNumber: Scalars['Int'];
  from: Scalars['String'];
  id: Scalars['Int'];
  pool: GqlPoolV2;
  sender: Scalars['String'];
  timestamp: Scalars['Int'];
  timestampDate: Scalars['String'];
  to: Scalars['String'];
  tokenAmountIn: Scalars['String'];
  tokenAmountOut: Scalars['String'];
  tokenIn: Scalars['String'];
  tokenInSym: Scalars['String'];
  tokenOut: Scalars['String'];
  tokenOutSym: Scalars['String'];
  tx: Scalars['String'];
}

export interface GqlV3Pool extends GqlPool {
  __typename: 'GqlV3Pool';
  address: Scalars['String'];
  aprItems: Array<Maybe<GqlPoolAprItem>>;
  blockNumber: Scalars['Int'];
  categories: Array<Maybe<GqlPoolCategory>>;
  chainId: Scalars['Int'];
  createTime: Scalars['Int'];
  decimals?: Maybe<Scalars['Int']>;
  dynamicData?: Maybe<GqlV3PoolDynamicData>;
  feeTier: Scalars['Int'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slot0: GqlV3PoolSlotZeroData;
  swapFee: Scalars['Float'];
  symbol?: Maybe<Scalars['String']>;
  token0: GqlToken;
  token1: GqlToken;
  type: GqlPoolType;
}

export interface GqlV3PoolDynamicData extends GqlPoolDynamicDataBase {
  __typename: 'GqlV3PoolDynamicData';
  blockNumber: Scalars['Int'];
  fees24h: Scalars['Float'];
  liquidity: Scalars['String'];
  lpPrice: Scalars['String'];
  reserves0: Scalars['String'];
  reserves1: Scalars['String'];
  totalLiquidity: Scalars['Float'];
  volume24h: Scalars['Float'];
}

export interface GqlV3PoolSlotZeroData {
  __typename: 'GqlV3PoolSlotZeroData';
  feeProtocol: Scalars['Int'];
  sqrtPriceX96: Scalars['String'];
  tick?: Maybe<Scalars['Int']>;
}

export interface IMintBurnV2Base {
  amount0?: Maybe<Scalars['String']>;
  amount1?: Maybe<Scalars['String']>;
  amountUSD?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  pair: GqlPoolV2;
  sender?: Maybe<Scalars['Bytes']>;
  timestamp: Scalars['BigInt'];
  tx: Scalars['String'];
}

export interface IRecentActivityItem {
  account: Scalars['String'];
  amount0?: Maybe<Scalars['String']>;
  amount1?: Maybe<Scalars['String']>;
  amountUSD: Scalars['Float'];
  dateLabel?: Maybe<Scalars['String']>;
  pool?: Maybe<GqlPool>;
  poolType: GqlPoolType;
  timestamp: Scalars['Int'];
  tx: Scalars['String'];
  type: RecentActivityType;
}

export interface MintBurnV2Base extends IMintBurnV2Base {
  __typename: 'MintBurnV2Base';
  amount0?: Maybe<Scalars['String']>;
  amount1?: Maybe<Scalars['String']>;
  amountUSD?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  pair: GqlPoolV2;
  sender?: Maybe<Scalars['Bytes']>;
  timestamp: Scalars['BigInt'];
  tx: Scalars['String'];
}

export interface MintV2 {
  __typename: 'MintV2';
  amount0?: Maybe<Scalars['String']>;
  amount1?: Maybe<Scalars['String']>;
  amountUSD?: Maybe<Scalars['String']>;
  feeLiquidity?: Maybe<Scalars['String']>;
  feeTo?: Maybe<Scalars['Bytes']>;
  id: Scalars['ID'];
  liquidity: Scalars['String'];
  logIndex?: Maybe<Scalars['BigInt']>;
  pair: GqlPoolV2;
  sender?: Maybe<Scalars['Bytes']>;
  timestamp: Scalars['BigInt'];
  to: Scalars['Bytes'];
  tx: Scalars['String'];
}

export interface MintV3 {
  __typename: 'MintV3';
  amount: Scalars['String'];
  amount0: Scalars['String'];
  amount1: Scalars['String'];
  amountUSD?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  origin: Scalars['Bytes'];
  owner: Scalars['Bytes'];
  pool: GqlV3Pool;
  sender?: Maybe<Scalars['Bytes']>;
  tickLower: Scalars['String'];
  tickUpper: Scalars['String'];
  timestamp: Scalars['Int'];
  tx: Scalars['String'];
}

export interface Mutation {
  __typename: 'Mutation';
  addStakers: Scalars['String'];
  addV2PoolsManual: Array<Maybe<GqlPoolV2>>;
  backFillCandleStickData: Scalars['String'];
  blacklistV2Pool: Scalars['String'];
  chainId: Scalars['Int'];
  initAllSnapshotsForV2Pool: Scalars['String'];
  initAllSnapshotsForV3Pool: Scalars['String'];
  initSubgraphV2PoolsSync: Scalars['String'];
  initSyncProtocolAllTimeV2Metrics: Scalars['String'];
  initSyncProtocolAllTimeV3Metrics: Scalars['String'];
  initSyncV2PoolsBurns: Scalars['String'];
  initSyncV2PoolsMints: Scalars['String'];
  initSyncV2TokenDayData: Scalars['String'];
  initSyncV3PoolsHourDatas: Scalars['String'];
  loadAllSnapshotsForV2Pools: Array<Maybe<Scalars['String']>>;
  setQuoteTokens: Scalars['String'];
  snycV2PoolTokens: Scalars['String'];
  syncAllTokenLists: Scalars['String'];
  syncAllV2PoolsFromSubgraph: Scalars['String'];
  syncHistoricSwapsforV2Pool: Scalars['String'];
  syncLatestSnapshotsForAllV3Pools: Scalars['String'];
  syncLatestSnapshotsForV2AllPools: Scalars['String'];
  syncMasterChefInfo: Scalars['String'];
  syncMissingSubgraphV2Pools: Scalars['String'];
  syncMissingSubgraphV3Pools: Scalars['String'];
  syncNFTPools: Scalars['String'];
  syncNewV2PoolsFromSubgraph: Scalars['String'];
  syncNewV3PoolsFromSubgraph: Scalars['String'];
  syncProtocolAllTimeV2Metrics: Scalars['String'];
  syncProtocolAllTimeV3Metrics: Scalars['String'];
  syncProtocolMetrics: Scalars['String'];
  syncStakers: Scalars['String'];
  syncSwapsForLast48Hours: Scalars['String'];
  syncSwapsforV2Pool: Scalars['String'];
  syncSwapsforV3Pool: Array<Maybe<Scalars['String']>>;
  syncTokenList: Scalars['String'];
  syncTokenPrices: Scalars['String'];
  syncV2PoolsBurns: Scalars['String'];
  syncV2PoolsHourDatas: Scalars['String'];
  syncV2PoolsMints: Scalars['String'];
  syncV2SubgraphTokens: Scalars['String'];
  syncV2Swaps: Scalars['String'];
  syncV2TokenDayData: Scalars['String'];
  syncV3PoolsBurns: Scalars['String'];
  syncV3PoolsHourDatas: Scalars['String'];
  syncV3PoolsMints: Scalars['String'];
  syncV3Swaps: Scalars['String'];
  syncV3SwapsForLast48Hours: Array<Maybe<Scalars['String']>>;
  syncV3TokenHourData: Scalars['String'];
  updateLiquidityValuesForV2Pool: Scalars['String'];
  updateLiquidityValuesForV2Pools: Scalars['String'];
  updateLiquidityValuesForV3Pools: Scalars['String'];
  updateManualVolumeAndFeesPools: Scalars['String'];
  updateSwapAprForV2Pool: Scalars['String'];
  updateSwapAprForV2Pools: Scalars['String'];
  updateSwapAprForV3Pools: Scalars['String'];
  updateV2FarmAprs: Scalars['String'];
  updateV3PoolRewardAprs: Scalars['String'];
  updateVolumeAndFeeValuesForAllV2Pools: Scalars['String'];
  updateVolumeAndFeeValuesForAllV3Pools: Scalars['String'];
  updateVolumeAndFeeValuesForV2Pool: Scalars['String'];
}

export interface MutationAddStakersArgs {
  input: AddStakersInput;
}

export interface MutationAddV2PoolsManualArgs {
  args: AddPoolsManualInput;
}

export interface MutationBackFillCandleStickDataArgs {
  chainId: Scalars['Int'];
  hoursToSync: Scalars['Int'];
  startingTimestamp: Scalars['Int'];
}

export interface MutationBlacklistV2PoolArgs {
  poolIds: Array<InputMaybe<Scalars['String']>>;
}

export interface MutationInitAllSnapshotsForV2PoolArgs {
  chainId: Scalars['Int'];
  poolAddress: Scalars['String'];
}

export interface MutationInitAllSnapshotsForV3PoolArgs {
  chainId: Scalars['Int'];
  poolAddress: Scalars['String'];
}

export interface MutationInitSubgraphV2PoolsSyncArgs {
  chainId: Scalars['Int'];
}

export interface MutationInitSyncProtocolAllTimeV2MetricsArgs {
  chainId: Scalars['Int'];
}

export interface MutationInitSyncProtocolAllTimeV3MetricsArgs {
  chainId: Scalars['Int'];
}

export interface MutationInitSyncV2PoolsBurnsArgs {
  chainId: Scalars['Int'];
}

export interface MutationInitSyncV2PoolsMintsArgs {
  chainId: Scalars['Int'];
}

export interface MutationInitSyncV2TokenDayDataArgs {
  chainId: Scalars['Int'];
}

export interface MutationInitSyncV3PoolsHourDatasArgs {
  chainId: Scalars['Int'];
}

export interface MutationLoadAllSnapshotsForV2PoolsArgs {
  chainId: Scalars['Int'];
}

export interface MutationSetQuoteTokensArgs {
  chainId: Scalars['Int'];
  setTrue: Scalars['Boolean'];
  tokens: Array<Scalars['String']>;
}

export interface MutationSnycV2PoolTokensArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncAllV2PoolsFromSubgraphArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncHistoricSwapsforV2PoolArgs {
  chainId: Scalars['Int'];
  poolAddress: Scalars['String'];
  startingTimestampInPast?: InputMaybe<Scalars['Int']>;
}

export interface MutationSyncLatestSnapshotsForAllV3PoolsArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncLatestSnapshotsForV2AllPoolsArgs {
  chainId: Scalars['Int'];
  daysToSync?: InputMaybe<Scalars['Int']>;
}

export interface MutationSyncMasterChefInfoArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncMissingSubgraphV2PoolsArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncMissingSubgraphV3PoolsArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncNftPoolsArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncNewV2PoolsFromSubgraphArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncNewV3PoolsFromSubgraphArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncProtocolAllTimeV2MetricsArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncProtocolAllTimeV3MetricsArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncProtocolMetricsArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncStakersArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncSwapsForLast48HoursArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncSwapsforV2PoolArgs {
  chainId: Scalars['Int'];
  poolAddress: Scalars['String'];
}

export interface MutationSyncSwapsforV3PoolArgs {
  chainId: Scalars['Int'];
  poolAddress: Scalars['String'];
}

export interface MutationSyncTokenListArgs {
  args: ChainIdInput;
}

export interface MutationSyncTokenPricesArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncV2PoolsBurnsArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncV2PoolsHourDatasArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncV2PoolsMintsArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncV2SubgraphTokensArgs {
  chainId: Scalars['Int'];
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface MutationSyncV2SwapsArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncV2TokenDayDataArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncV3PoolsBurnsArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncV3PoolsHourDatasArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncV3PoolsMintsArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncV3SwapsArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncV3SwapsForLast48HoursArgs {
  chainId: Scalars['Int'];
}

export interface MutationSyncV3TokenHourDataArgs {
  chainId: Scalars['Int'];
}

export interface MutationUpdateLiquidityValuesForV2PoolArgs {
  chainId: Scalars['Int'];
  poolAddress: Scalars['String'];
}

export interface MutationUpdateLiquidityValuesForV2PoolsArgs {
  chainId: Scalars['Int'];
}

export interface MutationUpdateLiquidityValuesForV3PoolsArgs {
  chainId: Scalars['Int'];
  poolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

export interface MutationUpdateManualVolumeAndFeesPoolsArgs {
  chainId: Scalars['Int'];
}

export interface MutationUpdateSwapAprForV2PoolArgs {
  chainId: Scalars['Int'];
  poolAddress: Scalars['String'];
}

export interface MutationUpdateSwapAprForV2PoolsArgs {
  chainId: Scalars['Int'];
}

export interface MutationUpdateSwapAprForV3PoolsArgs {
  chainId: Scalars['Int'];
}

export interface MutationUpdateV2FarmAprsArgs {
  chainId: Scalars['Int'];
}

export interface MutationUpdateV3PoolRewardAprsArgs {
  chainId: Scalars['Int'];
}

export interface MutationUpdateVolumeAndFeeValuesForAllV2PoolsArgs {
  chainId: Scalars['Int'];
}

export interface MutationUpdateVolumeAndFeeValuesForAllV3PoolsArgs {
  chainId: Scalars['Int'];
  poolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

export interface MutationUpdateVolumeAndFeeValuesForV2PoolArgs {
  chainId: Scalars['Int'];
  poolAddress: Scalars['String'];
}

export interface NftPool {
  __typename: 'NFTPool';
  address: Scalars['String'];
  allocPoints: Scalars['Int'];
  allocPointsWETH: Scalars['Int'];
  aprInfo: NftPoolAprInfo;
  chainId: Scalars['Int'];
  id: Scalars['ID'];
  lastRewardTime: Scalars['Int'];
  lpSupply: Scalars['Float'];
  lpSupplyWithMultiplier: Scalars['Float'];
  lpToken: Scalars['String'];
  maxBoostMultiplier: Scalars['Int'];
  maxGlobalMultiplier: Scalars['Int'];
  maxLockDuration: Scalars['Int'];
  maxLockMultiplier: Scalars['Int'];
  poolEmissionRate: Scalars['Float'];
  poolEmissionRateWETH: Scalars['Float'];
  positions: Array<Maybe<NftPoolPosition>>;
  tokenIds: Array<Maybe<Scalars['Int']>>;
  userLpBalance: Scalars['String'];
  v2Pool: GqlPoolV2;
  xTokenRewardsShare: Scalars['Int'];
}

export interface NftPoolAprInfo {
  __typename: 'NFTPoolAprInfo';
  apr: Scalars['Float'];
  maxApr: Scalars['Float'];
  maxBoostApr: Scalars['Float'];
  maxLockApr: Scalars['Float'];
  swapFeeApr: Scalars['Float'];
}

export interface NftPoolBalance {
  __typename: 'NFTPoolBalance';
  address: Scalars['String'];
  balance: Scalars['String'];
}

export interface NftPoolPosition {
  __typename: 'NFTPoolPosition';
  amount: Scalars['String'];
  amountWithMultiplier: Scalars['String'];
  aprInfo?: Maybe<NftPositionAprs>;
  boostPoints: Scalars['Float'];
  hasActiveLock: Scalars['Boolean'];
  id: Scalars['ID'];
  lockDuration: Scalars['Int'];
  lockEndDateLabel: Scalars['String'];
  lockMultiplier: Scalars['Float'];
  nftPool: NftPool;
  pendingRewards: Array<Maybe<NftPoolPositionReward>>;
  pendingRewardsValueUSD: Scalars['Float'];
  positionValueUsd: Scalars['Float'];
  startLockTime: Scalars['Int'];
  tokenId: Scalars['Int'];
  totalMultiplier: Scalars['Float'];
  user: GqlUser;
}

export interface NftPoolPositionReward {
  __typename: 'NFTPoolPositionReward';
  pendingRewards: Scalars['Float'];
  token: GqlToken;
  usdValue: Scalars['Float'];
}

export interface NftPoolTokenIds {
  __typename: 'NFTPoolTokenIds';
  nftPoolAddress: Scalars['String'];
  tokenIds: Array<Maybe<Scalars['String']>>;
}

export interface NftPositionAprs {
  __typename: 'NFTPositionAprs';
  lockAprBoost: Scalars['Float'];
  totalApr: Scalars['Float'];
  yieldBoosterBoostApr: Scalars['Float'];
}

export interface PoolAprInfo {
  __typename: 'PoolAprInfo';
  aprItems: Array<Maybe<GqlPoolAprItem>>;
  id: Scalars['ID'];
  pool: GqlPoolV2;
}

export interface PoolWhereInput {
  addresses: Array<InputMaybe<Scalars['String']>>;
  chainId: Scalars['Int'];
}

export interface Pool_Filter {
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PoolWhereInput>;
}

export interface ProtocolAllTimeV2Data {
  __typename: 'ProtocolAllTimeV2Data';
  id: Scalars['ID'];
  poolCount: Scalars['String'];
  totalFeesUSD: Scalars['Float'];
  totalLiquidityETH: Scalars['Float'];
  totalLiquidityUSD: Scalars['Float'];
  totalVolumeETH: Scalars['Float'];
  totalVolumeUSD: Scalars['Float'];
  txCount: Scalars['String'];
  untrackedVolumeUSD: Scalars['Float'];
}

export interface ProtocolAllTimeV3Data {
  __typename: 'ProtocolAllTimeV3Data';
  chainId: Scalars['Int'];
  id: Scalars['ID'];
  poolCount: Scalars['String'];
  totalFeesUSD: Scalars['Float'];
  totalValueLockedUSD: Scalars['Float'];
  totalValueLockedUSDUntracked: Scalars['Float'];
  totalVolumeUSD: Scalars['Float'];
  txCount: Scalars['String'];
  untrackedVolumeUSD: Scalars['Float'];
}

export interface ProtocolMetrics {
  __typename: 'ProtocolMetrics';
  allTimeV2?: Maybe<ProtocolAllTimeV2Data>;
  allTimeV3?: Maybe<ProtocolAllTimeV3Data>;
  date: Scalars['Int'];
  dateLabel: Scalars['String'];
  poolCount: Scalars['Float'];
  stakersTVL?: Maybe<Scalars['String']>;
  swapFee24h: Scalars['String'];
  swapVolume24h: Scalars['String'];
  totalLiquidity: Scalars['String'];
  totalLiquidityV2?: Maybe<Scalars['String']>;
  totalLiquidityV3?: Maybe<Scalars['String']>;
  totalSwapFee: Scalars['String'];
  totalSwapVolume: Scalars['String'];
  v2TotalVolume?: Maybe<Scalars['String']>;
  v3TotalVolume?: Maybe<Scalars['String']>;
}

export interface Query {
  __typename: 'Query';
  getAllTokens: Array<Maybe<GqlToken>>;
  getAllTokensForChain: Array<Maybe<GqlToken>>;
  getDayDataSnapshotsForToken: Array<Maybe<GqlTokenDayData>>;
  getNFTPool: Array<Maybe<NftPool>>;
  getNFTPools: Array<Maybe<NftPool>>;
  getPoolRecentActivity: Array<Maybe<RecentActivityItem>>;
  getPoolSwaps: Array<GqlPoolSwap>;
  getProtocolAllTimeV2Metrics: ProtocolAllTimeV2Data;
  getProtocolAllTimeV3Metrics: ProtocolAllTimeV3Data;
  getProtocolTokenPrice: Scalars['Float'];
  getRelevantV2PoolsForToken: Array<Maybe<GqlPoolV2>>;
  getRelevantV3PoolsForToken: Array<Maybe<GqlV3Pool>>;
  getStakers: Array<Maybe<GqlStaker>>;
  getStakingTVL: StakingTvlInfo;
  getTokenDayData?: Maybe<GqlTokenDayData>;
  getTokenDefinitions?: Maybe<Array<Maybe<GqlToken>>>;
  getTokenPrices: Array<Maybe<GqlTokenPrice>>;
  getTokenPricesForRange: Array<GqlTokenPriceChartDataItem>;
  getTokenRecentActivity: Array<Maybe<RecentActivityItem>>;
  getTokens: Array<Maybe<GqlToken>>;
  getTokensDayData: Array<Maybe<GqlTokenDayData>>;
  getUserNFTPoolPositionBalances: Array<Maybe<NftPoolBalance>>;
  getUserNFTPositionTokenIds: Array<Maybe<NftPoolTokenIds>>;
  getUserTransactionsHistory: Array<Maybe<UserTransaction>>;
  getUserV2PoolWalletBalances?: Maybe<AddressToV2PoolBalanceMap>;
  getV2AnalyticsTokensDayData: Array<Maybe<GqlTokenDayData>>;
  getV2PoolAPRs: Array<Maybe<PoolAprInfo>>;
  getV2PoolSwaps: Array<Maybe<GqlV2PoolSwap>>;
  getV2Pools: Array<Maybe<GqlPoolV2>>;
  getV2PoolsBurns: Array<Maybe<BurnV2>>;
  getV2PoolsMints: Array<Maybe<MintV2>>;
  getV2PoolsWithFarms: Array<Maybe<GqlPoolV2>>;
  getV2PoolsWithLiquidityCount: Scalars['Int'];
  getV2Quote?: Maybe<V2QuoteResponse>;
  getV3Pool?: Maybe<GqlV3Pool>;
  getV3Pools: Array<Maybe<GqlV3Pool>>;
  getV3PoolsBurns: Array<Maybe<BurnV3>>;
  getV3PoolsMints: Array<Maybe<MintV3>>;
  poolGetPool?: Maybe<GqlPoolV2>;
  poolGetPoolFilters: Array<GqlPoolFilterDefinition>;
  poolGetPools: Array<GqlPoolV2>;
  poolGetPoolsCount: Scalars['Int'];
  poolGetSnapshots: Array<GqlPoolSnapshot>;
  poolGetSwaps: Array<GqlV2PoolSwap>;
  poolGetV3PoolsCount: Scalars['Int'];
  protocolMetrics: ProtocolMetrics;
  protocolV2Metrics: ProtocolMetrics;
  protocolV3Metrics: ProtocolMetrics;
  tokenGetRelativePriceChartData: Array<GqlTokenPriceChartDataItem>;
  tokenGetTokenData?: Maybe<GqlToken>;
  tokenGetTokensData: Array<GqlToken>;
  userGetBaseNFTPoolPositionsInfo: Array<Maybe<NftPoolPosition>>;
  userGetStaking: Array<Maybe<GqlStaker>>;
}

export interface QueryGetAllTokensArgs {
  chainId?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetAllTokensForChainArgs {
  chainId?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetDayDataSnapshotsForTokenArgs {
  chainId: Scalars['Int'];
  range: GqlPoolSnapshotDataRange;
  tokenAddress: Scalars['String'];
}

export interface QueryGetNftPoolArgs {
  args: GetNftPoolArgs;
}

export interface QueryGetNftPoolsArgs {
  chainId?: InputMaybe<Scalars['Int']>;
  onlyWithAllocation?: InputMaybe<Scalars['Boolean']>;
}

export interface QueryGetPoolRecentActivityArgs {
  poolId: Scalars['String'];
  poolType: GqlPoolType;
}

export interface QueryGetPoolSwapsArgs {
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GqlPoolSwapFilter>;
}

export interface QueryGetProtocolAllTimeV2MetricsArgs {
  chainId: Scalars['Int'];
}

export interface QueryGetProtocolAllTimeV3MetricsArgs {
  chainId: Scalars['Int'];
}

export interface QueryGetProtocolTokenPriceArgs {
  chainId?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetRelevantV2PoolsForTokenArgs {
  chainId: Scalars['Int'];
  tokenAddress: Scalars['String'];
}

export interface QueryGetRelevantV3PoolsForTokenArgs {
  chainId: Scalars['Int'];
  tokenAddress: Scalars['String'];
}

export interface QueryGetStakersArgs {
  chainId?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetStakingTvlArgs {
  chainId: Scalars['Int'];
}

export interface QueryGetTokenDayDataArgs {
  chainId: Scalars['Int'];
  date?: InputMaybe<Scalars['Int']>;
  tokenAddress: Scalars['String'];
}

export interface QueryGetTokenDefinitionsArgs {
  chainId: Scalars['Int'];
}

export interface QueryGetTokenPricesArgs {
  chainId?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetTokenPricesForRangeArgs {
  chainId: Scalars['Int'];
  range: GqlTokenChartDataRange;
  tokenAddress: Scalars['String'];
}

export interface QueryGetTokenRecentActivityArgs {
  chainId: Scalars['Int'];
  tokenAddress: Scalars['String'];
}

export interface QueryGetTokensArgs {
  chainId?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetTokensDayDataArgs {
  chainId: Scalars['Int'];
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  tokens?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

export interface QueryGetUserNftPoolPositionBalancesArgs {
  account?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['Int']>;
  nftPoolAddresses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

export interface QueryGetUserNftPositionTokenIdsArgs {
  account?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['Int']>;
  nftPoolAddresses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tokenIdCounts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

export interface QueryGetUserTransactionsHistoryArgs {
  args: UserTransactionsInput;
}

export interface QueryGetUserV2PoolWalletBalancesArgs {
  account?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<GqlPoolFilter>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetV2AnalyticsTokensDayDataArgs {
  args?: InputMaybe<QueryPageInput>;
  chainId: Scalars['Int'];
  date?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GetV2TokenAnalyticsOrderBy>;
}

export interface QueryGetV2PoolApRsArgs {
  chainId: Scalars['Int'];
  pools: Array<Scalars['String']>;
}

export interface QueryGetV2PoolSwapsArgs {
  chainId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  pools: Array<Scalars['String']>;
}

export interface QueryGetV2PoolsArgs {
  chainId: Scalars['Int'];
}

export interface QueryGetV2PoolsBurnsArgs {
  chainId: Scalars['Int'];
  poolAddress: Scalars['String'];
}

export interface QueryGetV2PoolsMintsArgs {
  chainId: Scalars['Int'];
  poolAddress: Scalars['String'];
}

export interface QueryGetV2PoolsWithFarmsArgs {
  chainId: Scalars['Int'];
}

export interface QueryGetV2PoolsWithLiquidityCountArgs {
  chainId: Scalars['Int'];
}

export interface QueryGetV2QuoteArgs {
  args: V2QuoteInput;
}

export interface QueryGetV3PoolArgs {
  id: Scalars['String'];
}

export interface QueryGetV3PoolsArgs {
  chainId?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GqlPoolOrderBy>;
  orderDirection?: InputMaybe<GqlPoolOrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  textSearch?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GqlPoolFilter>;
}

export interface QueryGetV3PoolsBurnsArgs {
  chainId: Scalars['Int'];
  poolAddress: Scalars['String'];
}

export interface QueryGetV3PoolsMintsArgs {
  chainId: Scalars['Int'];
  poolAddress: Scalars['String'];
}

export interface QueryPoolGetPoolArgs {
  poolId: Scalars['String'];
}

export interface QueryPoolGetPoolsArgs {
  chainId?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GqlPoolOrderBy>;
  orderDirection?: InputMaybe<GqlPoolOrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  textSearch?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GqlPoolFilter>;
}

export interface QueryPoolGetPoolsCountArgs {
  chainId?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GqlPoolOrderBy>;
  orderDirection?: InputMaybe<GqlPoolOrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  textSearch?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GqlPoolFilter>;
}

export interface QueryPoolGetSnapshotsArgs {
  id: Scalars['String'];
  range: GqlPoolSnapshotDataRange;
  type: GqlPoolType;
}

export interface QueryPoolGetSwapsArgs {
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GqlPoolSwapFilter>;
}

export interface QueryPoolGetV3PoolsCountArgs {
  chainId?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GqlPoolOrderBy>;
  orderDirection?: InputMaybe<GqlPoolOrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  textSearch?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GqlPoolFilter>;
}

export interface QueryProtocolMetricsArgs {
  chainId?: InputMaybe<Scalars['Int']>;
}

export interface QueryProtocolV2MetricsArgs {
  chainId?: InputMaybe<Scalars['Int']>;
}

export interface QueryProtocolV3MetricsArgs {
  chainId?: InputMaybe<Scalars['Int']>;
}

export interface QueryTokenGetRelativePriceChartDataArgs {
  chainId?: InputMaybe<Scalars['Int']>;
  range: GqlTokenChartDataRange;
  tokenIn: Scalars['String'];
  tokenOut: Scalars['String'];
}

export interface QueryTokenGetTokenDataArgs {
  address: Scalars['String'];
  chainId: Scalars['Int'];
}

export interface QueryTokenGetTokensDataArgs {
  addresses: Array<Scalars['String']>;
  chainId: Scalars['Int'];
}

export interface QueryUserGetBaseNftPoolPositionsInfoArgs {
  account?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['Int']>;
}

export interface QueryUserGetStakingArgs {
  account?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['Int']>;
}

export interface QueryPageInput {
  chainId: Scalars['Int'];
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface RecentActivityItem extends IRecentActivityItem {
  __typename: 'RecentActivityItem';
  account: Scalars['String'];
  amount0?: Maybe<Scalars['String']>;
  amount1?: Maybe<Scalars['String']>;
  amountUSD: Scalars['Float'];
  dateLabel?: Maybe<Scalars['String']>;
  isTokenBuy?: Maybe<Scalars['Boolean']>;
  pool?: Maybe<GqlPool>;
  poolType: GqlPoolType;
  timestamp: Scalars['Int'];
  token?: Maybe<GqlToken>;
  tx: Scalars['String'];
  type: RecentActivityType;
}

export type RecentActivityType = 'Add' | 'Remove' | 'Swap';

export interface StakingTvlInfo {
  __typename: 'StakingTVLInfo';
  stakersTVL: Scalars['String'];
  totalYieldBoostTVL: Scalars['String'];
  tvl: Scalars['String'];
  tvlNum: Scalars['Float'];
}

export interface SwapOptionsInput {
  maxHops?: InputMaybe<Scalars['Int']>;
}

export interface SwapResult {
  __typename: 'SwapResult';
  amount: Scalars['String'];
  poolId: Scalars['String'];
}

export interface SwapRoute {
  __typename: 'SwapRoute';
  hops?: Maybe<Array<Maybe<SwapRouteHop>>>;
  tokenIn: Scalars['String'];
  tokenInAmount: Scalars['String'];
  tokenOut: Scalars['String'];
  tokenOutAmount: Scalars['String'];
}

export interface SwapRouteHop {
  __typename: 'SwapRouteHop';
  pool: GqlPoolV2;
  poolId: Scalars['String'];
  tokenIn: Scalars['String'];
  tokenInAmount: Scalars['String'];
  tokenOut: Scalars['String'];
  tokenOutAmount: Scalars['String'];
}

export interface UserDataQueryArgs {
  account: Scalars['String'];
  chainId: Scalars['Int'];
  refetch?: InputMaybe<Scalars['Boolean']>;
}

export interface UserStakingInfo {
  __typename: 'UserStakingInfo';
  depositTokenBalance: Scalars['String'];
  pendingRewardAmount: Scalars['Float'];
  pendingRewardValue: Scalars['Float'];
  poolAllowance: Scalars['String'];
  stakedBalance: Scalars['String'];
  stakedBalanceValue: Scalars['String'];
}

export interface UserTransaction extends IRecentActivityItem {
  __typename: 'UserTransaction';
  account: Scalars['String'];
  amount0: Scalars['String'];
  amount1: Scalars['String'];
  amountUSD: Scalars['Float'];
  dateLabel?: Maybe<Scalars['String']>;
  pool?: Maybe<GqlPool>;
  poolType: GqlPoolType;
  timestamp: Scalars['Int'];
  token0: Scalars['String'];
  token1: Scalars['String'];
  tx: Scalars['String'];
  type: RecentActivityType;
}

export interface UserTransactionsInput {
  chainId: Scalars['Int'];
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  user: Scalars['String'];
}

export interface V2QuoteInput {
  chainId: Scalars['Int'];
  swapAmount: Scalars['String'];
  swapOptions?: InputMaybe<SwapOptionsInput>;
  swapType: GqlTradeType;
  tokenIn: Scalars['String'];
  tokenOut: Scalars['String'];
}

export interface V2QuoteResponse {
  __typename: 'V2QuoteResponse';
  priceImpact?: Maybe<Scalars['String']>;
  returnAmount?: Maybe<Scalars['String']>;
  routes: Array<Maybe<SwapRoute>>;
  swapAmount?: Maybe<Scalars['String']>;
  swapType: GqlTradeType;
  swaps: Array<Maybe<SwapResult>>;
  tokenIn: Scalars['String'];
  tokenOut: Scalars['String'];
}

export interface V3PoolHourData {
  __typename: 'V3PoolHourData';
  close: Scalars['String'];
  feesUSD: Scalars['Float'];
  high: Scalars['String'];
  id: Scalars['ID'];
  liquidity: Scalars['String'];
  low: Scalars['String'];
  open: Scalars['String'];
  pool: GqlV3Pool;
  sqrtPrice: Scalars['String'];
  tick?: Maybe<Scalars['Int']>;
  timestamp: Scalars['Int'];
  token0Price: Scalars['String'];
  token1Price: Scalars['String'];
  tvlUSD: Scalars['Float'];
  txCount: Scalars['String'];
  volumeToken0: Scalars['Float'];
  volumeToken1: Scalars['Float'];
  volumeUSD: Scalars['Float'];
}

export type GetPoolRecentActivityQueryVariables = Exact<{
  poolId: Scalars['String'];
  poolType: GqlPoolType;
}>;

export type GetPoolRecentActivityQuery = {
  __typename: 'Query';
  getPoolRecentActivity: Array<{
    __typename: 'RecentActivityItem';
    type: RecentActivityType;
    poolType: GqlPoolType;
    amount0?: string | null;
    amount1?: string | null;
    amountUSD: number;
    timestamp: number;
    tx: string;
  } | null>;
};

export type GetTokenRecentActivityQueryVariables = Exact<{
  chainId: Scalars['Int'];
  tokenAddress: Scalars['String'];
}>;

export type GetTokenRecentActivityQuery = {
  __typename: 'Query';
  getTokenRecentActivity: Array<{
    __typename: 'RecentActivityItem';
    type: RecentActivityType;
    poolType: GqlPoolType;
    amountUSD: number;
    timestamp: number;
    tx: string;
    token?: { __typename: 'GqlToken'; symbol: string } | null;
  } | null>;
};

export type GetV2AnalyticsTokensDayDataQueryVariables = Exact<{
  chainId: Scalars['Int'];
  date?: InputMaybe<Scalars['Int']>;
  args?: InputMaybe<QueryPageInput>;
  orderBy?: InputMaybe<GetV2TokenAnalyticsOrderBy>;
}>;

export type GetV2AnalyticsTokensDayDataQuery = {
  __typename: 'Query';
  getV2AnalyticsTokensDayData: Array<{
    __typename: 'GqlTokenDayData';
    id: string;
    dailyVolumeToken: string;
    dailyVolumeETH: string;
    dailyVolumeUSD: string;
    feesUSD24hr: number;
    dailyTxns: number;
    date: number;
    dateLabel: string;
    token: {
      __typename: 'GqlToken';
      id?: string | null;
      address: string;
      symbol: string;
      logoURI?: string | null;
      tradeable: boolean;
    };
  } | null>;
};

export type GetDayDataSnapshotsForTokenQueryVariables = Exact<{
  chainId: Scalars['Int'];
  tokenAddress: Scalars['String'];
  range: GqlPoolSnapshotDataRange;
}>;

export type GetDayDataSnapshotsForTokenQuery = {
  __typename: 'Query';
  getDayDataSnapshotsForToken: Array<{
    __typename: 'GqlTokenDayData';
    id: string;
    dailyVolumeToken: string;
    dailyVolumeETH: string;
    dailyVolumeUSD: string;
    feesUSD24hr: number;
    dailyTxns: number;
    date: number;
    dateLabel: string;
    token: {
      __typename: 'GqlToken';
      id?: string | null;
      address: string;
      symbol: string;
      logoURI?: string | null;
      tradeable: boolean;
    };
  } | null>;
};

export type GetRelevantV2PoolsForTokenQueryVariables = Exact<{
  chainId: Scalars['Int'];
  tokenAddress: Scalars['String'];
}>;

export type GetRelevantV2PoolsForTokenQuery = {
  __typename: 'Query';
  getRelevantV2PoolsForToken: Array<{
    __typename: 'GqlPoolV2';
    id: string;
    address: string;
    name?: string | null;
    symbol?: string | null;
    type: GqlPoolType;
    swapFee: number;
    aprItems: Array<{
      __typename: 'GqlPoolAprItem';
      id: string;
      title: string;
      type: string;
      apr: number;
    } | null>;
    dynamicData?: {
      __typename: 'GqlV2PoolDynamicData';
      totalLiquidity: number;
      fees24h: number;
      volume24h: number;
    } | null;
    token0: {
      __typename: 'GqlToken';
      id?: string | null;
      address: string;
      name: string;
      symbol: string;
      decimals: number;
      chainId: number;
      logoURI?: string | null;
      tradeable: boolean;
      isQuoteToken: boolean;
      isStableCoin: boolean;
      currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
    };
    token1: {
      __typename: 'GqlToken';
      id?: string | null;
      address: string;
      name: string;
      symbol: string;
      decimals: number;
      chainId: number;
      logoURI?: string | null;
      tradeable: boolean;
      isQuoteToken: boolean;
      isStableCoin: boolean;
      currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
    };
  } | null>;
};

export type GetRelevantV3PoolsForTokenQueryVariables = Exact<{
  chainId: Scalars['Int'];
  tokenAddress: Scalars['String'];
}>;

export type GetRelevantV3PoolsForTokenQuery = {
  __typename: 'Query';
  getRelevantV3PoolsForToken: Array<{
    __typename: 'GqlV3Pool';
    id: string;
    address: string;
    name?: string | null;
    symbol?: string | null;
    type: GqlPoolType;
    swapFee: number;
    aprItems: Array<{
      __typename: 'GqlPoolAprItem';
      id: string;
      title: string;
      type: string;
      apr: number;
    } | null>;
    dynamicData?: {
      __typename: 'GqlV3PoolDynamicData';
      totalLiquidity: number;
      fees24h: number;
      volume24h: number;
    } | null;
    token0: {
      __typename: 'GqlToken';
      id?: string | null;
      address: string;
      name: string;
      symbol: string;
      decimals: number;
      chainId: number;
      logoURI?: string | null;
      tradeable: boolean;
      isQuoteToken: boolean;
      isStableCoin: boolean;
      currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
    };
    token1: {
      __typename: 'GqlToken';
      id?: string | null;
      address: string;
      name: string;
      symbol: string;
      decimals: number;
      chainId: number;
      logoURI?: string | null;
      tradeable: boolean;
      isQuoteToken: boolean;
      isStableCoin: boolean;
      currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
    };
  } | null>;
};

export type GqlTokenDayDataFragmentFragment = {
  __typename: 'GqlTokenDayData';
  id: string;
  dailyVolumeToken: string;
  dailyVolumeETH: string;
  dailyVolumeUSD: string;
  feesUSD24hr: number;
  dailyTxns: number;
  date: number;
  dateLabel: string;
  token: {
    __typename: 'GqlToken';
    id?: string | null;
    address: string;
    symbol: string;
    logoURI?: string | null;
    tradeable: boolean;
  };
};

export type GetPoolQueryVariables = Exact<{
  poolId: Scalars['String'];
}>;

export type GetPoolQuery = {
  __typename: 'Query';
  pool?: {
    __typename: 'GqlPoolV2';
    id: string;
    address: string;
    name?: string | null;
    symbol?: string | null;
    createTime: number;
    swapFee: number;
    type: GqlPoolType;
    categories: Array<{ __typename: 'GqlPoolCategory'; category: string } | null>;
    aprItems: Array<{
      __typename: 'GqlPoolAprItem';
      id: string;
      title: string;
      type: string;
      apr: number;
    } | null>;
    dynamicData?: {
      __typename: 'GqlV2PoolDynamicData';
      reserves0: string;
      reserves1: string;
      totalLiquidity: number;
      totalLiquidity24hAgo: string;
      totalShares: string;
      totalShares24hAgo: string;
      fees24h: number;
      volume24h: number;
      fees48h: string;
      volume48h: string;
      lifetimeVolume: string;
      lifetimeSwapFees: string;
      holdersCount: string;
      swapsCount: string;
      sharePriceAth: string;
      sharePriceAthTimestamp: number;
      sharePriceAtl: string;
      sharePriceAtlTimestamp: number;
      totalLiquidityAth: string;
      totalLiquidityAthTimestamp: number;
      totalLiquidityAtl: string;
      totalLiquidityAtlTimestamp: number;
      volume24hAth: string;
      volume24hAthTimestamp: number;
      volume24hAtl: string;
      volume24hAtlTimestamp: number;
      fees24hAth: string;
      fees24hAthTimestamp: number;
      fees24hAtl: string;
      fees24hAtlTimestamp: number;
    } | null;
    token0: {
      __typename: 'GqlToken';
      id?: string | null;
      address: string;
      name: string;
      symbol: string;
      decimals: number;
      chainId: number;
      logoURI?: string | null;
      tradeable: boolean;
      isQuoteToken: boolean;
      isStableCoin: boolean;
      currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
    };
    token1: {
      __typename: 'GqlToken';
      id?: string | null;
      address: string;
      name: string;
      symbol: string;
      decimals: number;
      chainId: number;
      logoURI?: string | null;
      tradeable: boolean;
      isQuoteToken: boolean;
      isStableCoin: boolean;
      currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
    };
    nftPool?: { __typename: 'NFTPool'; address: string } | null;
  } | null;
};

export type GetPoolSnapshotsQueryVariables = Exact<{
  poolId: Scalars['String'];
  range: GqlPoolSnapshotDataRange;
  type: GqlPoolType;
}>;

export type GetPoolSnapshotsQuery = {
  __typename: 'Query';
  snapshots: Array<{
    __typename: 'GqlPoolSnapshot';
    id: string;
    timestamp: number;
    totalLiquidity: string;
    volume24h: string;
    fees24h: string;
    sharePrice: string;
  }>;
};

export type GetPoolSwapsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GqlPoolSwapFilter>;
}>;

export type GetPoolSwapsQuery = {
  __typename: 'Query';
  swaps: Array<{
    __typename: 'GqlV2PoolSwap';
    id: number;
    timestamp: number;
    amount0In: string;
    amount1In: string;
    amount0Out: string;
    amount1Out: string;
    tokenIn: string;
    tokenOut: string;
    tokenAmountIn: string;
    tokenAmountOut: string;
    tx: string;
    from: string;
    amountUSD: number;
  }>;
};

export type GqlPoolV2MinimalFragment = {
  __typename: 'GqlPoolV2';
  id: string;
  address: string;
  name?: string | null;
  symbol?: string | null;
  createTime: number;
  swapFee: number;
  type: GqlPoolType;
  categories: Array<{ __typename: 'GqlPoolCategory'; category: string } | null>;
  aprItems: Array<{
    __typename: 'GqlPoolAprItem';
    id: string;
    title: string;
    type: string;
    apr: number;
  } | null>;
  dynamicData?: {
    __typename: 'GqlV2PoolDynamicData';
    reserves0: string;
    reserves1: string;
    totalLiquidity: number;
    totalLiquidity24hAgo: string;
    totalShares: string;
    totalShares24hAgo: string;
    fees24h: number;
    volume24h: number;
    fees48h: string;
    volume48h: string;
    lifetimeVolume: string;
    lifetimeSwapFees: string;
    holdersCount: string;
    swapsCount: string;
    sharePriceAth: string;
    sharePriceAthTimestamp: number;
    sharePriceAtl: string;
    sharePriceAtlTimestamp: number;
    totalLiquidityAth: string;
    totalLiquidityAthTimestamp: number;
    totalLiquidityAtl: string;
    totalLiquidityAtlTimestamp: number;
    volume24hAth: string;
    volume24hAthTimestamp: number;
    volume24hAtl: string;
    volume24hAtlTimestamp: number;
    fees24hAth: string;
    fees24hAthTimestamp: number;
    fees24hAtl: string;
    fees24hAtlTimestamp: number;
  } | null;
  token0: {
    __typename: 'GqlToken';
    id?: string | null;
    address: string;
    name: string;
    symbol: string;
    decimals: number;
    chainId: number;
    logoURI?: string | null;
    tradeable: boolean;
    isQuoteToken: boolean;
    isStableCoin: boolean;
    currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
  };
  token1: {
    __typename: 'GqlToken';
    id?: string | null;
    address: string;
    name: string;
    symbol: string;
    decimals: number;
    chainId: number;
    logoURI?: string | null;
    tradeable: boolean;
    isQuoteToken: boolean;
    isStableCoin: boolean;
    currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
  };
  nftPool?: { __typename: 'NFTPool'; address: string } | null;
};

export type V2PoolDynamicDataFragmentFragment = {
  __typename: 'GqlV2PoolDynamicData';
  reserves0: string;
  reserves1: string;
  totalLiquidity: number;
  totalLiquidity24hAgo: string;
  totalShares: string;
  totalShares24hAgo: string;
  fees24h: number;
  volume24h: number;
  fees48h: string;
  volume48h: string;
  lifetimeVolume: string;
  lifetimeSwapFees: string;
  holdersCount: string;
  swapsCount: string;
  sharePriceAth: string;
  sharePriceAthTimestamp: number;
  sharePriceAtl: string;
  sharePriceAtlTimestamp: number;
  totalLiquidityAth: string;
  totalLiquidityAthTimestamp: number;
  totalLiquidityAtl: string;
  totalLiquidityAtlTimestamp: number;
  volume24hAth: string;
  volume24hAthTimestamp: number;
  volume24hAtl: string;
  volume24hAtlTimestamp: number;
  fees24hAth: string;
  fees24hAthTimestamp: number;
  fees24hAtl: string;
  fees24hAtlTimestamp: number;
};

export type GetNftPoolsQueryVariables = Exact<{
  chainId?: InputMaybe<Scalars['Int']>;
  onlyWithAllocation?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetNftPoolsQuery = {
  __typename: 'Query';
  getNFTPools: Array<{
    __typename: 'NFTPool';
    id: string;
    address: string;
    lpToken: string;
    lpSupply: number;
    lpSupplyWithMultiplier: number;
    allocPoints: number;
    allocPointsWETH: number;
    poolEmissionRate: number;
    poolEmissionRateWETH: number;
    xTokenRewardsShare: number;
    maxGlobalMultiplier: number;
    maxLockDuration: number;
    maxLockMultiplier: number;
    maxBoostMultiplier: number;
    v2Pool: {
      __typename: 'GqlPoolV2';
      id: string;
      address: string;
      symbol?: string | null;
      token0: { __typename: 'GqlToken'; address: string; symbol: string };
      token1: { __typename: 'GqlToken'; address: string; symbol: string };
      aprItems: Array<{
        __typename: 'GqlPoolAprItem';
        id: string;
        title: string;
        type: string;
        apr: number;
      } | null>;
      dynamicData?: {
        __typename: 'GqlV2PoolDynamicData';
        reserves0: string;
        reserves1: string;
        totalLiquidity: number;
        totalLiquidity24hAgo: string;
        totalShares: string;
        totalShares24hAgo: string;
        fees24h: number;
        volume24h: number;
        fees48h: string;
        volume48h: string;
        lifetimeVolume: string;
        lifetimeSwapFees: string;
        holdersCount: string;
        swapsCount: string;
        sharePriceAth: string;
        sharePriceAthTimestamp: number;
        sharePriceAtl: string;
        sharePriceAtlTimestamp: number;
        totalLiquidityAth: string;
        totalLiquidityAthTimestamp: number;
        totalLiquidityAtl: string;
        totalLiquidityAtlTimestamp: number;
        volume24hAth: string;
        volume24hAthTimestamp: number;
        volume24hAtl: string;
        volume24hAtlTimestamp: number;
        fees24hAth: string;
        fees24hAthTimestamp: number;
        fees24hAtl: string;
        fees24hAtlTimestamp: number;
      } | null;
    };
  } | null>;
};

export type GetNftPoolQueryVariables = Exact<{
  args: GetNftPoolArgs;
}>;

export type GetNftPoolQuery = {
  __typename: 'Query';
  getNFTPool: Array<{
    __typename: 'NFTPool';
    id: string;
    address: string;
    lpToken: string;
    lpSupply: number;
    lpSupplyWithMultiplier: number;
    allocPoints: number;
    allocPointsWETH: number;
    poolEmissionRate: number;
    poolEmissionRateWETH: number;
    xTokenRewardsShare: number;
    maxGlobalMultiplier: number;
    maxLockDuration: number;
    maxLockMultiplier: number;
    maxBoostMultiplier: number;
    v2Pool: {
      __typename: 'GqlPoolV2';
      id: string;
      address: string;
      symbol?: string | null;
      token0: { __typename: 'GqlToken'; address: string; symbol: string };
      token1: { __typename: 'GqlToken'; address: string; symbol: string };
      aprItems: Array<{
        __typename: 'GqlPoolAprItem';
        id: string;
        title: string;
        type: string;
        apr: number;
      } | null>;
      dynamicData?: {
        __typename: 'GqlV2PoolDynamicData';
        reserves0: string;
        reserves1: string;
        totalLiquidity: number;
        totalLiquidity24hAgo: string;
        totalShares: string;
        totalShares24hAgo: string;
        fees24h: number;
        volume24h: number;
        fees48h: string;
        volume48h: string;
        lifetimeVolume: string;
        lifetimeSwapFees: string;
        holdersCount: string;
        swapsCount: string;
        sharePriceAth: string;
        sharePriceAthTimestamp: number;
        sharePriceAtl: string;
        sharePriceAtlTimestamp: number;
        totalLiquidityAth: string;
        totalLiquidityAthTimestamp: number;
        totalLiquidityAtl: string;
        totalLiquidityAtlTimestamp: number;
        volume24hAth: string;
        volume24hAthTimestamp: number;
        volume24hAtl: string;
        volume24hAtlTimestamp: number;
        fees24hAth: string;
        fees24hAthTimestamp: number;
        fees24hAtl: string;
        fees24hAtlTimestamp: number;
      } | null;
    };
  } | null>;
};

export type NftPoolFragmentFragment = {
  __typename: 'NFTPool';
  id: string;
  address: string;
  lpToken: string;
  lpSupply: number;
  lpSupplyWithMultiplier: number;
  allocPoints: number;
  allocPointsWETH: number;
  poolEmissionRate: number;
  poolEmissionRateWETH: number;
  xTokenRewardsShare: number;
  maxGlobalMultiplier: number;
  maxLockDuration: number;
  maxLockMultiplier: number;
  maxBoostMultiplier: number;
  v2Pool: {
    __typename: 'GqlPoolV2';
    id: string;
    address: string;
    symbol?: string | null;
    token0: { __typename: 'GqlToken'; address: string; symbol: string };
    token1: { __typename: 'GqlToken'; address: string; symbol: string };
    aprItems: Array<{
      __typename: 'GqlPoolAprItem';
      id: string;
      title: string;
      type: string;
      apr: number;
    } | null>;
    dynamicData?: {
      __typename: 'GqlV2PoolDynamicData';
      reserves0: string;
      reserves1: string;
      totalLiquidity: number;
      totalLiquidity24hAgo: string;
      totalShares: string;
      totalShares24hAgo: string;
      fees24h: number;
      volume24h: number;
      fees48h: string;
      volume48h: string;
      lifetimeVolume: string;
      lifetimeSwapFees: string;
      holdersCount: string;
      swapsCount: string;
      sharePriceAth: string;
      sharePriceAthTimestamp: number;
      sharePriceAtl: string;
      sharePriceAtlTimestamp: number;
      totalLiquidityAth: string;
      totalLiquidityAthTimestamp: number;
      totalLiquidityAtl: string;
      totalLiquidityAtlTimestamp: number;
      volume24hAth: string;
      volume24hAthTimestamp: number;
      volume24hAtl: string;
      volume24hAtlTimestamp: number;
      fees24hAth: string;
      fees24hAthTimestamp: number;
      fees24hAtl: string;
      fees24hAtlTimestamp: number;
    } | null;
  };
};

export type GetPoolsQueryVariables = Exact<{
  chainId?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GqlPoolOrderBy>;
  orderDirection?: InputMaybe<GqlPoolOrderDirection>;
  where?: InputMaybe<GqlPoolFilter>;
  textSearch?: InputMaybe<Scalars['String']>;
}>;

export type GetPoolsQuery = {
  __typename: 'Query';
  count: number;
  poolGetPools: Array<{
    __typename: 'GqlPoolV2';
    id: string;
    address: string;
    name?: string | null;
    symbol?: string | null;
    createTime: number;
    swapFee: number;
    type: GqlPoolType;
    categories: Array<{ __typename: 'GqlPoolCategory'; category: string } | null>;
    aprItems: Array<{
      __typename: 'GqlPoolAprItem';
      id: string;
      title: string;
      type: string;
      apr: number;
    } | null>;
    dynamicData?: {
      __typename: 'GqlV2PoolDynamicData';
      reserves0: string;
      reserves1: string;
      totalLiquidity: number;
      totalLiquidity24hAgo: string;
      totalShares: string;
      totalShares24hAgo: string;
      fees24h: number;
      volume24h: number;
      fees48h: string;
      volume48h: string;
      lifetimeVolume: string;
      lifetimeSwapFees: string;
      holdersCount: string;
      swapsCount: string;
      sharePriceAth: string;
      sharePriceAthTimestamp: number;
      sharePriceAtl: string;
      sharePriceAtlTimestamp: number;
      totalLiquidityAth: string;
      totalLiquidityAthTimestamp: number;
      totalLiquidityAtl: string;
      totalLiquidityAtlTimestamp: number;
      volume24hAth: string;
      volume24hAthTimestamp: number;
      volume24hAtl: string;
      volume24hAtlTimestamp: number;
      fees24hAth: string;
      fees24hAthTimestamp: number;
      fees24hAtl: string;
      fees24hAtlTimestamp: number;
    } | null;
    token0: {
      __typename: 'GqlToken';
      id?: string | null;
      address: string;
      name: string;
      symbol: string;
      decimals: number;
      chainId: number;
      logoURI?: string | null;
      tradeable: boolean;
      isQuoteToken: boolean;
      isStableCoin: boolean;
      currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
    };
    token1: {
      __typename: 'GqlToken';
      id?: string | null;
      address: string;
      name: string;
      symbol: string;
      decimals: number;
      chainId: number;
      logoURI?: string | null;
      tradeable: boolean;
      isQuoteToken: boolean;
      isStableCoin: boolean;
      currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
    };
    nftPool?: { __typename: 'NFTPool'; address: string } | null;
  }>;
};

export type GetPoolFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type GetPoolFiltersQuery = {
  __typename: 'Query';
  filters: Array<{ __typename: 'GqlPoolFilterDefinition'; id: string; title: string }>;
};

export type GetV3PoolsQueryVariables = Exact<{
  chainId?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GqlPoolOrderBy>;
  orderDirection?: InputMaybe<GqlPoolOrderDirection>;
  where?: InputMaybe<GqlPoolFilter>;
  textSearch?: InputMaybe<Scalars['String']>;
}>;

export type GetV3PoolsQuery = {
  __typename: 'Query';
  count: number;
  getV3Pools: Array<{
    __typename: 'GqlV3Pool';
    id: string;
    address: string;
    symbol?: string | null;
    feeTier: number;
    swapFee: number;
    type: GqlPoolType;
    token0: {
      __typename: 'GqlToken';
      id?: string | null;
      address: string;
      name: string;
      symbol: string;
      decimals: number;
      chainId: number;
      logoURI?: string | null;
      tradeable: boolean;
      isQuoteToken: boolean;
      isStableCoin: boolean;
      currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
    };
    token1: {
      __typename: 'GqlToken';
      id?: string | null;
      address: string;
      name: string;
      symbol: string;
      decimals: number;
      chainId: number;
      logoURI?: string | null;
      tradeable: boolean;
      isQuoteToken: boolean;
      isStableCoin: boolean;
      currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
    };
    aprItems: Array<{
      __typename: 'GqlPoolAprItem';
      id: string;
      title: string;
      type: string;
      apr: number;
    } | null>;
    dynamicData?: {
      __typename: 'GqlV3PoolDynamicData';
      totalLiquidity: number;
      fees24h: number;
      volume24h: number;
      reserves0: string;
      reserves1: string;
    } | null;
  } | null>;
};

export type GetV3PoolQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetV3PoolQuery = {
  __typename: 'Query';
  getV3Pool?: {
    __typename: 'GqlV3Pool';
    id: string;
    address: string;
    symbol?: string | null;
    feeTier: number;
    swapFee: number;
    type: GqlPoolType;
    token0: {
      __typename: 'GqlToken';
      id?: string | null;
      address: string;
      name: string;
      symbol: string;
      decimals: number;
      chainId: number;
      logoURI?: string | null;
      tradeable: boolean;
      isQuoteToken: boolean;
      isStableCoin: boolean;
      currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
    };
    token1: {
      __typename: 'GqlToken';
      id?: string | null;
      address: string;
      name: string;
      symbol: string;
      decimals: number;
      chainId: number;
      logoURI?: string | null;
      tradeable: boolean;
      isQuoteToken: boolean;
      isStableCoin: boolean;
      currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
    };
    aprItems: Array<{
      __typename: 'GqlPoolAprItem';
      id: string;
      title: string;
      type: string;
      apr: number;
    } | null>;
    dynamicData?: {
      __typename: 'GqlV3PoolDynamicData';
      totalLiquidity: number;
      fees24h: number;
      volume24h: number;
      reserves0: string;
      reserves1: string;
    } | null;
  } | null;
};

export type GqlV3PoolFragmentFragment = {
  __typename: 'GqlV3Pool';
  id: string;
  address: string;
  symbol?: string | null;
  feeTier: number;
  swapFee: number;
  type: GqlPoolType;
  token0: {
    __typename: 'GqlToken';
    id?: string | null;
    address: string;
    name: string;
    symbol: string;
    decimals: number;
    chainId: number;
    logoURI?: string | null;
    tradeable: boolean;
    isQuoteToken: boolean;
    isStableCoin: boolean;
    currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
  };
  token1: {
    __typename: 'GqlToken';
    id?: string | null;
    address: string;
    name: string;
    symbol: string;
    decimals: number;
    chainId: number;
    logoURI?: string | null;
    tradeable: boolean;
    isQuoteToken: boolean;
    isStableCoin: boolean;
    currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
  };
  aprItems: Array<{
    __typename: 'GqlPoolAprItem';
    id: string;
    title: string;
    type: string;
    apr: number;
  } | null>;
  dynamicData?: {
    __typename: 'GqlV3PoolDynamicData';
    totalLiquidity: number;
    fees24h: number;
    volume24h: number;
    reserves0: string;
    reserves1: string;
  } | null;
};

export type GetStakersQueryVariables = Exact<{
  chainId?: InputMaybe<Scalars['Int']>;
  account?: InputMaybe<Scalars['String']>;
}>;

export type GetStakersQuery = {
  __typename: 'Query';
  getStakers: Array<{
    __typename: 'GqlStaker';
    address: string;
    totalDepositAmount: number;
    rewardPerSecond: string;
    totalDepositValueUSD: number;
    apr: number;
    depositFee: number;
    depositToken: {
      __typename: 'GqlToken';
      id?: string | null;
      address: string;
      name: string;
      symbol: string;
      decimals: number;
      chainId: number;
      logoURI?: string | null;
      tradeable: boolean;
      isQuoteToken: boolean;
      isStableCoin: boolean;
      currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
    };
    rewardToken: {
      __typename: 'GqlToken';
      id?: string | null;
      address: string;
      name: string;
      symbol: string;
      decimals: number;
      chainId: number;
      logoURI?: string | null;
      tradeable: boolean;
      isQuoteToken: boolean;
      isStableCoin: boolean;
      currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
    };
  } | null>;
  userGetStaking: Array<{
    __typename: 'GqlStaker';
    userData?: {
      __typename: 'UserStakingInfo';
      poolAllowance: string;
      stakedBalance: string;
      stakedBalanceValue: string;
      pendingRewardAmount: number;
      pendingRewardValue: number;
      depositTokenBalance: string;
    } | null;
  } | null>;
};

export type GetTokenRelativePriceChartDataQueryVariables = Exact<{
  tokenIn: Scalars['String'];
  tokenOut: Scalars['String'];
  range: GqlTokenChartDataRange;
  chainId?: InputMaybe<Scalars['Int']>;
}>;

export type GetTokenRelativePriceChartDataQuery = {
  __typename: 'Query';
  prices: Array<{
    __typename: 'GqlTokenPriceChartDataItem';
    id: string;
    price: any;
    timestamp: number;
  }>;
};

export type GetTradeSelectedTokenDataQueryVariables = Exact<{
  chainId: Scalars['Int'];
  tokenIn: Scalars['String'];
  tokenOut: Scalars['String'];
}>;

export type GetTradeSelectedTokenDataQuery = {
  __typename: 'Query';
  tokenInData?: { __typename: 'GqlToken'; id?: string | null; address: string } | null;
  tokenOutData?: { __typename: 'GqlToken'; id?: string | null; address: string } | null;
};

export type GetV2SwapQuoteQueryVariables = Exact<{
  args: V2QuoteInput;
}>;

export type GetV2SwapQuoteQuery = {
  __typename: 'Query';
  swaps?: {
    __typename: 'V2QuoteResponse';
    tokenIn: string;
    tokenOut: string;
    swapAmount?: string | null;
    swapType: GqlTradeType;
    priceImpact?: string | null;
    swaps: Array<{ __typename: 'SwapResult'; poolId: string; amount: string } | null>;
    routes: Array<{
      __typename: 'SwapRoute';
      tokenIn: string;
      tokenOut: string;
      tokenInAmount: string;
      tokenOutAmount: string;
      hops?: Array<{
        __typename: 'SwapRouteHop';
        poolId: string;
        tokenIn: string;
        tokenOut: string;
        tokenInAmount: string;
        tokenOutAmount: string;
        pool: {
          __typename: 'GqlPoolV2';
          id: string;
          name?: string | null;
          symbol?: string | null;
          dynamicData?: { __typename: 'GqlV2PoolDynamicData'; totalLiquidity: number } | null;
        };
      } | null> | null;
    } | null>;
  } | null;
};

export type GqlTokenFragmentFragment = {
  __typename: 'GqlToken';
  id?: string | null;
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  chainId: number;
  logoURI?: string | null;
  tradeable: boolean;
  isQuoteToken: boolean;
  isStableCoin: boolean;
  currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
};

export type GqlTokenPriceFragmentFragment = {
  __typename: 'GqlTokenPrice';
  price: number;
  token: { __typename: 'GqlToken'; address: string };
};

export type ProtocolMetricsFragmentFragment = {
  __typename: 'ProtocolMetrics';
  totalLiquidity: string;
  totalSwapVolume: string;
  totalSwapFee: string;
  poolCount: number;
  swapFee24h: string;
  swapVolume24h: string;
  v2TotalVolume?: string | null;
  v3TotalVolume?: string | null;
  totalLiquidityV2?: string | null;
  totalLiquidityV3?: string | null;
  stakersTVL?: string | null;
  allTimeV2?: {
    __typename: 'ProtocolAllTimeV2Data';
    totalVolumeUSD: number;
    totalFeesUSD: number;
  } | null;
  allTimeV3?: {
    __typename: 'ProtocolAllTimeV3Data';
    totalVolumeUSD: number;
    totalFeesUSD: number;
  } | null;
};

export type GetAppGlobalDataQueryVariables = Exact<{
  chainId?: InputMaybe<Scalars['Int']>;
}>;

export type GetAppGlobalDataQuery = {
  __typename: 'Query';
  getAllTokens: Array<{
    __typename: 'GqlToken';
    id?: string | null;
    address: string;
    name: string;
    symbol: string;
    decimals: number;
    chainId: number;
    logoURI?: string | null;
    tradeable: boolean;
    isQuoteToken: boolean;
    isStableCoin: boolean;
    currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
  } | null>;
  getTokenPrices: Array<{
    __typename: 'GqlTokenPrice';
    price: number;
    token: { __typename: 'GqlToken'; address: string };
  } | null>;
};

export type GetAppGlobalPollingDataQueryVariables = Exact<{
  chainId?: InputMaybe<Scalars['Int']>;
}>;

export type GetAppGlobalPollingDataQuery = {
  __typename: 'Query';
  getTokenPrices: Array<{
    __typename: 'GqlTokenPrice';
    price: number;
    token: { __typename: 'GqlToken'; address: string };
  } | null>;
  protocolMetrics: {
    __typename: 'ProtocolMetrics';
    totalLiquidity: string;
    totalSwapVolume: string;
    totalSwapFee: string;
    poolCount: number;
    swapFee24h: string;
    swapVolume24h: string;
    v2TotalVolume?: string | null;
    v3TotalVolume?: string | null;
    totalLiquidityV2?: string | null;
    totalLiquidityV3?: string | null;
    stakersTVL?: string | null;
    allTimeV2?: {
      __typename: 'ProtocolAllTimeV2Data';
      totalVolumeUSD: number;
      totalFeesUSD: number;
    } | null;
    allTimeV3?: {
      __typename: 'ProtocolAllTimeV3Data';
      totalVolumeUSD: number;
      totalFeesUSD: number;
    } | null;
  };
};

export type GetTokensQueryVariables = Exact<{
  chainId?: InputMaybe<Scalars['Int']>;
}>;

export type GetTokensQuery = {
  __typename: 'Query';
  tokens: Array<{
    __typename: 'GqlToken';
    id?: string | null;
    address: string;
    name: string;
    symbol: string;
    decimals: number;
    chainId: number;
    logoURI?: string | null;
    tradeable: boolean;
    isQuoteToken: boolean;
    isStableCoin: boolean;
    currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
  } | null>;
};

export type GetTokenPricesQueryVariables = Exact<{
  chainId: Scalars['Int'];
}>;

export type GetTokenPricesQuery = {
  __typename: 'Query';
  tokenPrices: Array<{
    __typename: 'GqlTokenPrice';
    price: number;
    token: { __typename: 'GqlToken'; address: string };
  } | null>;
};

export type GetProtocolDataQueryVariables = Exact<{
  chainId?: InputMaybe<Scalars['Int']>;
}>;

export type GetProtocolDataQuery = {
  __typename: 'Query';
  protocolTokenPrice: number;
  protocolData: {
    __typename: 'ProtocolMetrics';
    totalLiquidity: string;
    totalSwapVolume: string;
    totalSwapFee: string;
    poolCount: number;
    swapFee24h: string;
    swapVolume24h: string;
    v2TotalVolume?: string | null;
    v3TotalVolume?: string | null;
    totalLiquidityV2?: string | null;
    totalLiquidityV3?: string | null;
    stakersTVL?: string | null;
    allTimeV2?: {
      __typename: 'ProtocolAllTimeV2Data';
      totalVolumeUSD: number;
      totalFeesUSD: number;
    } | null;
    allTimeV3?: {
      __typename: 'ProtocolAllTimeV3Data';
      totalVolumeUSD: number;
      totalFeesUSD: number;
    } | null;
  };
};

export type GetProtocolTokenPriceQueryVariables = Exact<{
  chainId?: InputMaybe<Scalars['Int']>;
}>;

export type GetProtocolTokenPriceQuery = { __typename: 'Query'; protocolTokenPrice: number };

export type GetAllTokensForChainQueryVariables = Exact<{
  chainId?: InputMaybe<Scalars['Int']>;
}>;

export type GetAllTokensForChainQuery = {
  __typename: 'Query';
  tokens: Array<{
    __typename: 'GqlToken';
    id?: string | null;
    address: string;
    name: string;
    symbol: string;
    decimals: number;
    chainId: number;
    logoURI?: string | null;
    tradeable: boolean;
    isQuoteToken: boolean;
    isStableCoin: boolean;
    currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
  } | null>;
};

export type GetUserDataQueryVariables = Exact<{
  chainId?: InputMaybe<Scalars['Int']>;
  account?: InputMaybe<Scalars['String']>;
}>;

export type GetUserDataQuery = {
  __typename: 'Query';
  staking: Array<{
    __typename: 'GqlStaker';
    address: string;
    rewardPerSecond: string;
    totalDepositAmount: number;
    totalDepositValueUSD: number;
    depositFee: number;
    apr: number;
    depositToken: {
      __typename: 'GqlToken';
      address: string;
      symbol: string;
      currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
    };
    rewardToken: {
      __typename: 'GqlToken';
      address: string;
      symbol: string;
      currentPrice?: { __typename: 'GqlTokenCurrentPrice'; price?: number | null } | null;
    };
    userData?: {
      __typename: 'UserStakingInfo';
      poolAllowance: string;
      stakedBalance: string;
      stakedBalanceValue: string;
      pendingRewardAmount: number;
      pendingRewardValue: number;
      depositTokenBalance: string;
    } | null;
  } | null>;
};

export type GetUserNftPositionsQueryVariables = Exact<{
  chainId?: InputMaybe<Scalars['Int']>;
  account?: InputMaybe<Scalars['String']>;
}>;

export type GetUserNftPositionsQuery = {
  __typename: 'Query';
  nftPositions: Array<{
    __typename: 'NFTPoolPosition';
    tokenId: number;
    totalMultiplier: number;
    positionValueUsd: number;
    amount: string;
    amountWithMultiplier: string;
    boostPoints: number;
    hasActiveLock: boolean;
    id: string;
    lockDuration: number;
    lockEndDateLabel: string;
    lockMultiplier: number;
    startLockTime: number;
    aprInfo?: {
      __typename: 'NFTPositionAprs';
      lockAprBoost: number;
      totalApr: number;
      yieldBoosterBoostApr: number;
    } | null;
    pendingRewards: Array<{
      __typename: 'NFTPoolPositionReward';
      pendingRewards: number;
      usdValue: number;
      token: { __typename: 'GqlToken'; address: string; name: string; symbol: string };
    } | null>;
    nftPool: {
      __typename: 'NFTPool';
      address: string;
      id: string;
      maxGlobalMultiplier: number;
      maxLockDuration: number;
      maxLockMultiplier: number;
      maxBoostMultiplier: number;
      v2Pool: { __typename: 'GqlPoolV2'; address: string; id: string };
    };
  } | null>;
};

export type GetUserV2WalletBalancesQueryVariables = Exact<{
  chainId?: InputMaybe<Scalars['Int']>;
  account?: InputMaybe<Scalars['String']>;
}>;

export type GetUserV2WalletBalancesQuery = {
  __typename: 'Query';
  balances?: {
    __typename: 'AddressToV2PoolBalanceMap';
    totalValueUSD: number;
    balances: Array<{
      __typename: 'ContractBalanceMap';
      balance: string;
      usdValue?: number | null;
      v2Pool: { __typename: 'GqlPoolV2'; address: string; id: string };
    }>;
  } | null;
};

export type GetUserTransactionsHistoryQueryVariables = Exact<{
  args: UserTransactionsInput;
}>;

export type GetUserTransactionsHistoryQuery = {
  __typename: 'Query';
  getUserTransactionsHistory: Array<{
    __typename: 'UserTransaction';
    type: RecentActivityType;
    poolType: GqlPoolType;
    amount0: string;
    amount1: string;
    amountUSD: number;
    timestamp: number;
    tx: string;
  } | null>;
};

export const GqlTokenDayDataFragmentFragmentDoc = gql`
  fragment GqlTokenDayDataFragment on GqlTokenDayData {
    id
    dailyVolumeToken
    dailyVolumeETH
    dailyVolumeUSD
    feesUSD24hr
    dailyTxns
    date
    dateLabel
    token {
      id
      address
      symbol
      logoURI
      tradeable
    }
  }
`;
export const V2PoolDynamicDataFragmentFragmentDoc = gql`
  fragment V2PoolDynamicDataFragment on GqlV2PoolDynamicData {
    reserves0
    reserves1
    totalLiquidity
    totalLiquidity24hAgo
    totalShares
    totalShares24hAgo
    fees24h
    volume24h
    fees48h
    volume48h
    lifetimeVolume
    lifetimeSwapFees
    holdersCount
    swapsCount
    sharePriceAth
    sharePriceAthTimestamp
    sharePriceAtl
    sharePriceAtlTimestamp
    totalLiquidityAth
    totalLiquidityAthTimestamp
    totalLiquidityAtl
    totalLiquidityAtlTimestamp
    volume24hAth
    volume24hAthTimestamp
    volume24hAtl
    volume24hAtlTimestamp
    fees24hAth
    fees24hAthTimestamp
    fees24hAtl
    fees24hAtlTimestamp
  }
`;
export const GqlTokenFragmentFragmentDoc = gql`
  fragment GqlTokenFragment on GqlToken {
    id
    address
    name
    symbol
    decimals
    chainId
    logoURI
    tradeable
    isQuoteToken
    isStableCoin
    currentPrice {
      price
    }
  }
`;
export const GqlPoolV2MinimalFragmentDoc = gql`
  fragment GqlPoolV2Minimal on GqlPoolV2 {
    id
    address
    name
    symbol
    createTime
    swapFee
    type
    categories {
      category
    }
    aprItems {
      id
      title
      type
      apr
    }
    dynamicData {
      ...V2PoolDynamicDataFragment
    }
    token0 {
      ...GqlTokenFragment
    }
    token1 {
      ...GqlTokenFragment
    }
    nftPool {
      address
    }
  }
  ${V2PoolDynamicDataFragmentFragmentDoc}
  ${GqlTokenFragmentFragmentDoc}
`;
export const NftPoolFragmentFragmentDoc = gql`
  fragment NFTPoolFragment on NFTPool {
    id
    address
    lpToken
    lpSupply
    lpSupplyWithMultiplier
    allocPoints
    allocPointsWETH
    poolEmissionRate
    poolEmissionRateWETH
    xTokenRewardsShare
    maxGlobalMultiplier
    maxLockDuration
    maxLockMultiplier
    maxBoostMultiplier
    v2Pool {
      id
      address
      symbol
      token0 {
        address
        symbol
      }
      token1 {
        address
        symbol
      }
      aprItems {
        id
        title
        type
        apr
      }
      dynamicData {
        ...V2PoolDynamicDataFragment
      }
    }
  }
  ${V2PoolDynamicDataFragmentFragmentDoc}
`;
export const GqlV3PoolFragmentFragmentDoc = gql`
  fragment GqlV3PoolFragment on GqlV3Pool {
    id
    address
    symbol
    feeTier
    swapFee
    type
    token0 {
      ...GqlTokenFragment
    }
    token1 {
      ...GqlTokenFragment
    }
    aprItems {
      id
      title
      type
      apr
    }
    dynamicData {
      totalLiquidity
      fees24h
      volume24h
      reserves0
      reserves1
    }
  }
  ${GqlTokenFragmentFragmentDoc}
`;
export const GqlTokenPriceFragmentFragmentDoc = gql`
  fragment GqlTokenPriceFragment on GqlTokenPrice {
    price
    token {
      address
    }
  }
`;
export const ProtocolMetricsFragmentFragmentDoc = gql`
  fragment ProtocolMetricsFragment on ProtocolMetrics {
    totalLiquidity
    totalSwapVolume
    totalSwapFee
    poolCount
    swapFee24h
    swapVolume24h
    v2TotalVolume
    v3TotalVolume
    totalLiquidityV2
    totalLiquidityV3
    stakersTVL
    allTimeV2 {
      totalVolumeUSD
      totalFeesUSD
    }
    allTimeV3 {
      totalVolumeUSD
      totalFeesUSD
    }
  }
`;
export const GetPoolRecentActivityDocument = gql`
  query GetPoolRecentActivity($poolId: String!, $poolType: GqlPoolType!) {
    getPoolRecentActivity(poolId: $poolId, poolType: $poolType) {
      type
      poolType
      amount0
      amount1
      amountUSD
      timestamp
      tx
    }
  }
`;

/**
 * __useGetPoolRecentActivityQuery__
 *
 * To run a query within a React component, call `useGetPoolRecentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoolRecentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoolRecentActivityQuery({
 *   variables: {
 *      poolId: // value for 'poolId'
 *      poolType: // value for 'poolType'
 *   },
 * });
 */
export function useGetPoolRecentActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPoolRecentActivityQuery,
    GetPoolRecentActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPoolRecentActivityQuery, GetPoolRecentActivityQueryVariables>(
    GetPoolRecentActivityDocument,
    options,
  );
}
export function useGetPoolRecentActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPoolRecentActivityQuery,
    GetPoolRecentActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPoolRecentActivityQuery, GetPoolRecentActivityQueryVariables>(
    GetPoolRecentActivityDocument,
    options,
  );
}
export type GetPoolRecentActivityQueryHookResult = ReturnType<typeof useGetPoolRecentActivityQuery>;
export type GetPoolRecentActivityLazyQueryHookResult = ReturnType<
  typeof useGetPoolRecentActivityLazyQuery
>;
export type GetPoolRecentActivityQueryResult = Apollo.QueryResult<
  GetPoolRecentActivityQuery,
  GetPoolRecentActivityQueryVariables
>;
export const GetTokenRecentActivityDocument = gql`
  query GetTokenRecentActivity($chainId: Int!, $tokenAddress: String!) {
    getTokenRecentActivity(chainId: $chainId, tokenAddress: $tokenAddress) {
      type
      poolType
      amountUSD
      timestamp
      token {
        symbol
      }
      tx
    }
  }
`;

/**
 * __useGetTokenRecentActivityQuery__
 *
 * To run a query within a React component, call `useGetTokenRecentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokenRecentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokenRecentActivityQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      tokenAddress: // value for 'tokenAddress'
 *   },
 * });
 */
export function useGetTokenRecentActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTokenRecentActivityQuery,
    GetTokenRecentActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTokenRecentActivityQuery, GetTokenRecentActivityQueryVariables>(
    GetTokenRecentActivityDocument,
    options,
  );
}
export function useGetTokenRecentActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTokenRecentActivityQuery,
    GetTokenRecentActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTokenRecentActivityQuery, GetTokenRecentActivityQueryVariables>(
    GetTokenRecentActivityDocument,
    options,
  );
}
export type GetTokenRecentActivityQueryHookResult = ReturnType<
  typeof useGetTokenRecentActivityQuery
>;
export type GetTokenRecentActivityLazyQueryHookResult = ReturnType<
  typeof useGetTokenRecentActivityLazyQuery
>;
export type GetTokenRecentActivityQueryResult = Apollo.QueryResult<
  GetTokenRecentActivityQuery,
  GetTokenRecentActivityQueryVariables
>;
export const GetV2AnalyticsTokensDayDataDocument = gql`
  query GetV2AnalyticsTokensDayData(
    $chainId: Int!
    $date: Int
    $args: QueryPageInput
    $orderBy: GetV2TokenAnalyticsOrderBy
  ) {
    getV2AnalyticsTokensDayData(chainId: $chainId, date: $date, args: $args, orderBy: $orderBy) {
      ...GqlTokenDayDataFragment
    }
  }
  ${GqlTokenDayDataFragmentFragmentDoc}
`;

/**
 * __useGetV2AnalyticsTokensDayDataQuery__
 *
 * To run a query within a React component, call `useGetV2AnalyticsTokensDayDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetV2AnalyticsTokensDayDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetV2AnalyticsTokensDayDataQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      date: // value for 'date'
 *      args: // value for 'args'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetV2AnalyticsTokensDayDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetV2AnalyticsTokensDayDataQuery,
    GetV2AnalyticsTokensDayDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetV2AnalyticsTokensDayDataQuery,
    GetV2AnalyticsTokensDayDataQueryVariables
  >(GetV2AnalyticsTokensDayDataDocument, options);
}
export function useGetV2AnalyticsTokensDayDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetV2AnalyticsTokensDayDataQuery,
    GetV2AnalyticsTokensDayDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetV2AnalyticsTokensDayDataQuery,
    GetV2AnalyticsTokensDayDataQueryVariables
  >(GetV2AnalyticsTokensDayDataDocument, options);
}
export type GetV2AnalyticsTokensDayDataQueryHookResult = ReturnType<
  typeof useGetV2AnalyticsTokensDayDataQuery
>;
export type GetV2AnalyticsTokensDayDataLazyQueryHookResult = ReturnType<
  typeof useGetV2AnalyticsTokensDayDataLazyQuery
>;
export type GetV2AnalyticsTokensDayDataQueryResult = Apollo.QueryResult<
  GetV2AnalyticsTokensDayDataQuery,
  GetV2AnalyticsTokensDayDataQueryVariables
>;
export const GetDayDataSnapshotsForTokenDocument = gql`
  query GetDayDataSnapshotsForToken(
    $chainId: Int!
    $tokenAddress: String!
    $range: GqlPoolSnapshotDataRange!
  ) {
    getDayDataSnapshotsForToken(chainId: $chainId, tokenAddress: $tokenAddress, range: $range) {
      ...GqlTokenDayDataFragment
    }
  }
  ${GqlTokenDayDataFragmentFragmentDoc}
`;

/**
 * __useGetDayDataSnapshotsForTokenQuery__
 *
 * To run a query within a React component, call `useGetDayDataSnapshotsForTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDayDataSnapshotsForTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDayDataSnapshotsForTokenQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      tokenAddress: // value for 'tokenAddress'
 *      range: // value for 'range'
 *   },
 * });
 */
export function useGetDayDataSnapshotsForTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDayDataSnapshotsForTokenQuery,
    GetDayDataSnapshotsForTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDayDataSnapshotsForTokenQuery,
    GetDayDataSnapshotsForTokenQueryVariables
  >(GetDayDataSnapshotsForTokenDocument, options);
}
export function useGetDayDataSnapshotsForTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDayDataSnapshotsForTokenQuery,
    GetDayDataSnapshotsForTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDayDataSnapshotsForTokenQuery,
    GetDayDataSnapshotsForTokenQueryVariables
  >(GetDayDataSnapshotsForTokenDocument, options);
}
export type GetDayDataSnapshotsForTokenQueryHookResult = ReturnType<
  typeof useGetDayDataSnapshotsForTokenQuery
>;
export type GetDayDataSnapshotsForTokenLazyQueryHookResult = ReturnType<
  typeof useGetDayDataSnapshotsForTokenLazyQuery
>;
export type GetDayDataSnapshotsForTokenQueryResult = Apollo.QueryResult<
  GetDayDataSnapshotsForTokenQuery,
  GetDayDataSnapshotsForTokenQueryVariables
>;
export const GetRelevantV2PoolsForTokenDocument = gql`
  query GetRelevantV2PoolsForToken($chainId: Int!, $tokenAddress: String!) {
    getRelevantV2PoolsForToken(chainId: $chainId, tokenAddress: $tokenAddress) {
      id
      address
      name
      symbol
      type
      swapFee
      aprItems {
        id
        title
        type
        apr
      }
      dynamicData {
        totalLiquidity
        fees24h
        volume24h
      }
      token0 {
        ...GqlTokenFragment
      }
      token1 {
        ...GqlTokenFragment
      }
    }
  }
  ${GqlTokenFragmentFragmentDoc}
`;

/**
 * __useGetRelevantV2PoolsForTokenQuery__
 *
 * To run a query within a React component, call `useGetRelevantV2PoolsForTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelevantV2PoolsForTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelevantV2PoolsForTokenQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      tokenAddress: // value for 'tokenAddress'
 *   },
 * });
 */
export function useGetRelevantV2PoolsForTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRelevantV2PoolsForTokenQuery,
    GetRelevantV2PoolsForTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRelevantV2PoolsForTokenQuery, GetRelevantV2PoolsForTokenQueryVariables>(
    GetRelevantV2PoolsForTokenDocument,
    options,
  );
}
export function useGetRelevantV2PoolsForTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRelevantV2PoolsForTokenQuery,
    GetRelevantV2PoolsForTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRelevantV2PoolsForTokenQuery,
    GetRelevantV2PoolsForTokenQueryVariables
  >(GetRelevantV2PoolsForTokenDocument, options);
}
export type GetRelevantV2PoolsForTokenQueryHookResult = ReturnType<
  typeof useGetRelevantV2PoolsForTokenQuery
>;
export type GetRelevantV2PoolsForTokenLazyQueryHookResult = ReturnType<
  typeof useGetRelevantV2PoolsForTokenLazyQuery
>;
export type GetRelevantV2PoolsForTokenQueryResult = Apollo.QueryResult<
  GetRelevantV2PoolsForTokenQuery,
  GetRelevantV2PoolsForTokenQueryVariables
>;
export const GetRelevantV3PoolsForTokenDocument = gql`
  query GetRelevantV3PoolsForToken($chainId: Int!, $tokenAddress: String!) {
    getRelevantV3PoolsForToken(chainId: $chainId, tokenAddress: $tokenAddress) {
      id
      address
      name
      symbol
      type
      swapFee
      aprItems {
        id
        title
        type
        apr
      }
      dynamicData {
        totalLiquidity
        fees24h
        volume24h
      }
      token0 {
        ...GqlTokenFragment
      }
      token1 {
        ...GqlTokenFragment
      }
    }
  }
  ${GqlTokenFragmentFragmentDoc}
`;

/**
 * __useGetRelevantV3PoolsForTokenQuery__
 *
 * To run a query within a React component, call `useGetRelevantV3PoolsForTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelevantV3PoolsForTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelevantV3PoolsForTokenQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      tokenAddress: // value for 'tokenAddress'
 *   },
 * });
 */
export function useGetRelevantV3PoolsForTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRelevantV3PoolsForTokenQuery,
    GetRelevantV3PoolsForTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRelevantV3PoolsForTokenQuery, GetRelevantV3PoolsForTokenQueryVariables>(
    GetRelevantV3PoolsForTokenDocument,
    options,
  );
}
export function useGetRelevantV3PoolsForTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRelevantV3PoolsForTokenQuery,
    GetRelevantV3PoolsForTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRelevantV3PoolsForTokenQuery,
    GetRelevantV3PoolsForTokenQueryVariables
  >(GetRelevantV3PoolsForTokenDocument, options);
}
export type GetRelevantV3PoolsForTokenQueryHookResult = ReturnType<
  typeof useGetRelevantV3PoolsForTokenQuery
>;
export type GetRelevantV3PoolsForTokenLazyQueryHookResult = ReturnType<
  typeof useGetRelevantV3PoolsForTokenLazyQuery
>;
export type GetRelevantV3PoolsForTokenQueryResult = Apollo.QueryResult<
  GetRelevantV3PoolsForTokenQuery,
  GetRelevantV3PoolsForTokenQueryVariables
>;
export const GetPoolDocument = gql`
  query GetPool($poolId: String!) {
    pool: poolGetPool(poolId: $poolId) {
      ...GqlPoolV2Minimal
    }
  }
  ${GqlPoolV2MinimalFragmentDoc}
`;

/**
 * __useGetPoolQuery__
 *
 * To run a query within a React component, call `useGetPoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoolQuery({
 *   variables: {
 *      poolId: // value for 'poolId'
 *   },
 * });
 */
export function useGetPoolQuery(
  baseOptions: Apollo.QueryHookOptions<GetPoolQuery, GetPoolQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPoolQuery, GetPoolQueryVariables>(GetPoolDocument, options);
}
export function useGetPoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPoolQuery, GetPoolQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPoolQuery, GetPoolQueryVariables>(GetPoolDocument, options);
}
export type GetPoolQueryHookResult = ReturnType<typeof useGetPoolQuery>;
export type GetPoolLazyQueryHookResult = ReturnType<typeof useGetPoolLazyQuery>;
export type GetPoolQueryResult = Apollo.QueryResult<GetPoolQuery, GetPoolQueryVariables>;
export const GetPoolSnapshotsDocument = gql`
  query GetPoolSnapshots($poolId: String!, $range: GqlPoolSnapshotDataRange!, $type: GqlPoolType!) {
    snapshots: poolGetSnapshots(id: $poolId, range: $range, type: $type) {
      id
      timestamp
      totalLiquidity
      volume24h
      fees24h
      sharePrice
    }
  }
`;

/**
 * __useGetPoolSnapshotsQuery__
 *
 * To run a query within a React component, call `useGetPoolSnapshotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoolSnapshotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoolSnapshotsQuery({
 *   variables: {
 *      poolId: // value for 'poolId'
 *      range: // value for 'range'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetPoolSnapshotsQuery(
  baseOptions: Apollo.QueryHookOptions<GetPoolSnapshotsQuery, GetPoolSnapshotsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPoolSnapshotsQuery, GetPoolSnapshotsQueryVariables>(
    GetPoolSnapshotsDocument,
    options,
  );
}
export function useGetPoolSnapshotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPoolSnapshotsQuery, GetPoolSnapshotsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPoolSnapshotsQuery, GetPoolSnapshotsQueryVariables>(
    GetPoolSnapshotsDocument,
    options,
  );
}
export type GetPoolSnapshotsQueryHookResult = ReturnType<typeof useGetPoolSnapshotsQuery>;
export type GetPoolSnapshotsLazyQueryHookResult = ReturnType<typeof useGetPoolSnapshotsLazyQuery>;
export type GetPoolSnapshotsQueryResult = Apollo.QueryResult<
  GetPoolSnapshotsQuery,
  GetPoolSnapshotsQueryVariables
>;
export const GetPoolSwapsDocument = gql`
  query GetPoolSwaps($first: Int, $skip: Int, $where: GqlPoolSwapFilter) {
    swaps: poolGetSwaps(first: $first, skip: $skip, where: $where) {
      id
      timestamp
      amount0In
      amount1In
      amount0Out
      amount1Out
      tokenIn
      tokenOut
      tokenAmountIn
      tokenAmountOut
      tx
      from
      amountUSD
    }
  }
`;

/**
 * __useGetPoolSwapsQuery__
 *
 * To run a query within a React component, call `useGetPoolSwapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoolSwapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoolSwapsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPoolSwapsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPoolSwapsQuery, GetPoolSwapsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPoolSwapsQuery, GetPoolSwapsQueryVariables>(
    GetPoolSwapsDocument,
    options,
  );
}
export function useGetPoolSwapsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPoolSwapsQuery, GetPoolSwapsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPoolSwapsQuery, GetPoolSwapsQueryVariables>(
    GetPoolSwapsDocument,
    options,
  );
}
export type GetPoolSwapsQueryHookResult = ReturnType<typeof useGetPoolSwapsQuery>;
export type GetPoolSwapsLazyQueryHookResult = ReturnType<typeof useGetPoolSwapsLazyQuery>;
export type GetPoolSwapsQueryResult = Apollo.QueryResult<
  GetPoolSwapsQuery,
  GetPoolSwapsQueryVariables
>;
export const GetNftPoolsDocument = gql`
  query GetNFTPools($chainId: Int, $onlyWithAllocation: Boolean) {
    getNFTPools(chainId: $chainId, onlyWithAllocation: $onlyWithAllocation) {
      ...NFTPoolFragment
    }
  }
  ${NftPoolFragmentFragmentDoc}
`;

/**
 * __useGetNftPoolsQuery__
 *
 * To run a query within a React component, call `useGetNftPoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNftPoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNftPoolsQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      onlyWithAllocation: // value for 'onlyWithAllocation'
 *   },
 * });
 */
export function useGetNftPoolsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNftPoolsQuery, GetNftPoolsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNftPoolsQuery, GetNftPoolsQueryVariables>(GetNftPoolsDocument, options);
}
export function useGetNftPoolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNftPoolsQuery, GetNftPoolsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNftPoolsQuery, GetNftPoolsQueryVariables>(
    GetNftPoolsDocument,
    options,
  );
}
export type GetNftPoolsQueryHookResult = ReturnType<typeof useGetNftPoolsQuery>;
export type GetNftPoolsLazyQueryHookResult = ReturnType<typeof useGetNftPoolsLazyQuery>;
export type GetNftPoolsQueryResult = Apollo.QueryResult<
  GetNftPoolsQuery,
  GetNftPoolsQueryVariables
>;
export const GetNftPoolDocument = gql`
  query GetNFTPool($args: GetNFTPoolArgs!) {
    getNFTPool(args: $args) {
      ...NFTPoolFragment
    }
  }
  ${NftPoolFragmentFragmentDoc}
`;

/**
 * __useGetNftPoolQuery__
 *
 * To run a query within a React component, call `useGetNftPoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNftPoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNftPoolQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useGetNftPoolQuery(
  baseOptions: Apollo.QueryHookOptions<GetNftPoolQuery, GetNftPoolQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNftPoolQuery, GetNftPoolQueryVariables>(GetNftPoolDocument, options);
}
export function useGetNftPoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNftPoolQuery, GetNftPoolQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNftPoolQuery, GetNftPoolQueryVariables>(
    GetNftPoolDocument,
    options,
  );
}
export type GetNftPoolQueryHookResult = ReturnType<typeof useGetNftPoolQuery>;
export type GetNftPoolLazyQueryHookResult = ReturnType<typeof useGetNftPoolLazyQuery>;
export type GetNftPoolQueryResult = Apollo.QueryResult<GetNftPoolQuery, GetNftPoolQueryVariables>;
export const GetPoolsDocument = gql`
  query GetPools(
    $chainId: Int
    $first: Int
    $skip: Int
    $orderBy: GqlPoolOrderBy
    $orderDirection: GqlPoolOrderDirection
    $where: GqlPoolFilter
    $textSearch: String
  ) {
    poolGetPools(
      chainId: $chainId
      first: $first
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: $where
      textSearch: $textSearch
    ) {
      ...GqlPoolV2Minimal
    }
    count: poolGetPoolsCount(
      chainId: $chainId
      first: $first
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: $where
      textSearch: $textSearch
    )
  }
  ${GqlPoolV2MinimalFragmentDoc}
`;

/**
 * __useGetPoolsQuery__
 *
 * To run a query within a React component, call `useGetPoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoolsQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      where: // value for 'where'
 *      textSearch: // value for 'textSearch'
 *   },
 * });
 */
export function useGetPoolsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPoolsQuery, GetPoolsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPoolsQuery, GetPoolsQueryVariables>(GetPoolsDocument, options);
}
export function useGetPoolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPoolsQuery, GetPoolsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPoolsQuery, GetPoolsQueryVariables>(GetPoolsDocument, options);
}
export type GetPoolsQueryHookResult = ReturnType<typeof useGetPoolsQuery>;
export type GetPoolsLazyQueryHookResult = ReturnType<typeof useGetPoolsLazyQuery>;
export type GetPoolsQueryResult = Apollo.QueryResult<GetPoolsQuery, GetPoolsQueryVariables>;
export const GetPoolFiltersDocument = gql`
  query GetPoolFilters {
    filters: poolGetPoolFilters {
      id
      title
    }
  }
`;

/**
 * __useGetPoolFiltersQuery__
 *
 * To run a query within a React component, call `useGetPoolFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoolFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoolFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPoolFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPoolFiltersQuery, GetPoolFiltersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPoolFiltersQuery, GetPoolFiltersQueryVariables>(
    GetPoolFiltersDocument,
    options,
  );
}
export function useGetPoolFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPoolFiltersQuery, GetPoolFiltersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPoolFiltersQuery, GetPoolFiltersQueryVariables>(
    GetPoolFiltersDocument,
    options,
  );
}
export type GetPoolFiltersQueryHookResult = ReturnType<typeof useGetPoolFiltersQuery>;
export type GetPoolFiltersLazyQueryHookResult = ReturnType<typeof useGetPoolFiltersLazyQuery>;
export type GetPoolFiltersQueryResult = Apollo.QueryResult<
  GetPoolFiltersQuery,
  GetPoolFiltersQueryVariables
>;
export const GetV3PoolsDocument = gql`
  query GetV3Pools(
    $chainId: Int
    $first: Int
    $skip: Int
    $orderBy: GqlPoolOrderBy
    $orderDirection: GqlPoolOrderDirection
    $where: GqlPoolFilter
    $textSearch: String
  ) {
    getV3Pools(
      chainId: $chainId
      first: $first
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: $where
      textSearch: $textSearch
    ) {
      ...GqlV3PoolFragment
    }
    count: poolGetV3PoolsCount(
      chainId: $chainId
      first: $first
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: $where
      textSearch: $textSearch
    )
  }
  ${GqlV3PoolFragmentFragmentDoc}
`;

/**
 * __useGetV3PoolsQuery__
 *
 * To run a query within a React component, call `useGetV3PoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetV3PoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetV3PoolsQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      where: // value for 'where'
 *      textSearch: // value for 'textSearch'
 *   },
 * });
 */
export function useGetV3PoolsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetV3PoolsQuery, GetV3PoolsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetV3PoolsQuery, GetV3PoolsQueryVariables>(GetV3PoolsDocument, options);
}
export function useGetV3PoolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetV3PoolsQuery, GetV3PoolsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetV3PoolsQuery, GetV3PoolsQueryVariables>(
    GetV3PoolsDocument,
    options,
  );
}
export type GetV3PoolsQueryHookResult = ReturnType<typeof useGetV3PoolsQuery>;
export type GetV3PoolsLazyQueryHookResult = ReturnType<typeof useGetV3PoolsLazyQuery>;
export type GetV3PoolsQueryResult = Apollo.QueryResult<GetV3PoolsQuery, GetV3PoolsQueryVariables>;
export const GetV3PoolDocument = gql`
  query GetV3Pool($id: String!) {
    getV3Pool(id: $id) {
      ...GqlV3PoolFragment
    }
  }
  ${GqlV3PoolFragmentFragmentDoc}
`;

/**
 * __useGetV3PoolQuery__
 *
 * To run a query within a React component, call `useGetV3PoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetV3PoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetV3PoolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetV3PoolQuery(
  baseOptions: Apollo.QueryHookOptions<GetV3PoolQuery, GetV3PoolQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetV3PoolQuery, GetV3PoolQueryVariables>(GetV3PoolDocument, options);
}
export function useGetV3PoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetV3PoolQuery, GetV3PoolQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetV3PoolQuery, GetV3PoolQueryVariables>(GetV3PoolDocument, options);
}
export type GetV3PoolQueryHookResult = ReturnType<typeof useGetV3PoolQuery>;
export type GetV3PoolLazyQueryHookResult = ReturnType<typeof useGetV3PoolLazyQuery>;
export type GetV3PoolQueryResult = Apollo.QueryResult<GetV3PoolQuery, GetV3PoolQueryVariables>;
export const GetStakersDocument = gql`
  query GetStakers($chainId: Int, $account: String) {
    getStakers(chainId: $chainId) {
      address
      totalDepositAmount
      rewardPerSecond
      totalDepositValueUSD
      apr
      depositFee
      depositToken {
        ...GqlTokenFragment
      }
      rewardToken {
        ...GqlTokenFragment
      }
    }
    userGetStaking(chainId: $chainId, account: $account) {
      userData {
        poolAllowance
        stakedBalance
        stakedBalanceValue
        pendingRewardAmount
        pendingRewardValue
        depositTokenBalance
      }
    }
  }
  ${GqlTokenFragmentFragmentDoc}
`;

/**
 * __useGetStakersQuery__
 *
 * To run a query within a React component, call `useGetStakersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStakersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStakersQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      account: // value for 'account'
 *   },
 * });
 */
export function useGetStakersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetStakersQuery, GetStakersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStakersQuery, GetStakersQueryVariables>(GetStakersDocument, options);
}
export function useGetStakersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStakersQuery, GetStakersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStakersQuery, GetStakersQueryVariables>(
    GetStakersDocument,
    options,
  );
}
export type GetStakersQueryHookResult = ReturnType<typeof useGetStakersQuery>;
export type GetStakersLazyQueryHookResult = ReturnType<typeof useGetStakersLazyQuery>;
export type GetStakersQueryResult = Apollo.QueryResult<GetStakersQuery, GetStakersQueryVariables>;
export const GetTokenRelativePriceChartDataDocument = gql`
  query GetTokenRelativePriceChartData(
    $tokenIn: String!
    $tokenOut: String!
    $range: GqlTokenChartDataRange!
    $chainId: Int
  ) {
    prices: tokenGetRelativePriceChartData(
      tokenIn: $tokenIn
      tokenOut: $tokenOut
      range: $range
      chainId: $chainId
    ) {
      id
      price
      timestamp
    }
  }
`;

/**
 * __useGetTokenRelativePriceChartDataQuery__
 *
 * To run a query within a React component, call `useGetTokenRelativePriceChartDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokenRelativePriceChartDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokenRelativePriceChartDataQuery({
 *   variables: {
 *      tokenIn: // value for 'tokenIn'
 *      tokenOut: // value for 'tokenOut'
 *      range: // value for 'range'
 *      chainId: // value for 'chainId'
 *   },
 * });
 */
export function useGetTokenRelativePriceChartDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTokenRelativePriceChartDataQuery,
    GetTokenRelativePriceChartDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTokenRelativePriceChartDataQuery,
    GetTokenRelativePriceChartDataQueryVariables
  >(GetTokenRelativePriceChartDataDocument, options);
}
export function useGetTokenRelativePriceChartDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTokenRelativePriceChartDataQuery,
    GetTokenRelativePriceChartDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTokenRelativePriceChartDataQuery,
    GetTokenRelativePriceChartDataQueryVariables
  >(GetTokenRelativePriceChartDataDocument, options);
}
export type GetTokenRelativePriceChartDataQueryHookResult = ReturnType<
  typeof useGetTokenRelativePriceChartDataQuery
>;
export type GetTokenRelativePriceChartDataLazyQueryHookResult = ReturnType<
  typeof useGetTokenRelativePriceChartDataLazyQuery
>;
export type GetTokenRelativePriceChartDataQueryResult = Apollo.QueryResult<
  GetTokenRelativePriceChartDataQuery,
  GetTokenRelativePriceChartDataQueryVariables
>;
export const GetTradeSelectedTokenDataDocument = gql`
  query GetTradeSelectedTokenData($chainId: Int!, $tokenIn: String!, $tokenOut: String!) {
    tokenInData: tokenGetTokenData(chainId: $chainId, address: $tokenIn) {
      id
      address
    }
    tokenOutData: tokenGetTokenData(chainId: $chainId, address: $tokenOut) {
      id
      address
    }
  }
`;

/**
 * __useGetTradeSelectedTokenDataQuery__
 *
 * To run a query within a React component, call `useGetTradeSelectedTokenDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTradeSelectedTokenDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTradeSelectedTokenDataQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      tokenIn: // value for 'tokenIn'
 *      tokenOut: // value for 'tokenOut'
 *   },
 * });
 */
export function useGetTradeSelectedTokenDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTradeSelectedTokenDataQuery,
    GetTradeSelectedTokenDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTradeSelectedTokenDataQuery, GetTradeSelectedTokenDataQueryVariables>(
    GetTradeSelectedTokenDataDocument,
    options,
  );
}
export function useGetTradeSelectedTokenDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTradeSelectedTokenDataQuery,
    GetTradeSelectedTokenDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTradeSelectedTokenDataQuery,
    GetTradeSelectedTokenDataQueryVariables
  >(GetTradeSelectedTokenDataDocument, options);
}
export type GetTradeSelectedTokenDataQueryHookResult = ReturnType<
  typeof useGetTradeSelectedTokenDataQuery
>;
export type GetTradeSelectedTokenDataLazyQueryHookResult = ReturnType<
  typeof useGetTradeSelectedTokenDataLazyQuery
>;
export type GetTradeSelectedTokenDataQueryResult = Apollo.QueryResult<
  GetTradeSelectedTokenDataQuery,
  GetTradeSelectedTokenDataQueryVariables
>;
export const GetV2SwapQuoteDocument = gql`
  query GetV2SwapQuote($args: V2QuoteInput!) {
    swaps: getV2Quote(args: $args) {
      tokenIn
      tokenOut
      swapAmount
      swapType
      priceImpact
      swaps {
        poolId
        amount
      }
      routes {
        tokenIn
        tokenOut
        tokenInAmount
        tokenOutAmount
        hops {
          poolId
          pool {
            id
            name
            symbol
            dynamicData {
              totalLiquidity
            }
          }
          tokenIn
          tokenOut
          tokenInAmount
          tokenOutAmount
        }
      }
    }
  }
`;

/**
 * __useGetV2SwapQuoteQuery__
 *
 * To run a query within a React component, call `useGetV2SwapQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetV2SwapQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetV2SwapQuoteQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useGetV2SwapQuoteQuery(
  baseOptions: Apollo.QueryHookOptions<GetV2SwapQuoteQuery, GetV2SwapQuoteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetV2SwapQuoteQuery, GetV2SwapQuoteQueryVariables>(
    GetV2SwapQuoteDocument,
    options,
  );
}
export function useGetV2SwapQuoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetV2SwapQuoteQuery, GetV2SwapQuoteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetV2SwapQuoteQuery, GetV2SwapQuoteQueryVariables>(
    GetV2SwapQuoteDocument,
    options,
  );
}
export type GetV2SwapQuoteQueryHookResult = ReturnType<typeof useGetV2SwapQuoteQuery>;
export type GetV2SwapQuoteLazyQueryHookResult = ReturnType<typeof useGetV2SwapQuoteLazyQuery>;
export type GetV2SwapQuoteQueryResult = Apollo.QueryResult<
  GetV2SwapQuoteQuery,
  GetV2SwapQuoteQueryVariables
>;
export const GetAppGlobalDataDocument = gql`
  query GetAppGlobalData($chainId: Int) {
    getAllTokens(chainId: $chainId) {
      ...GqlTokenFragment
    }
    getTokenPrices(chainId: $chainId) {
      ...GqlTokenPriceFragment
    }
  }
  ${GqlTokenFragmentFragmentDoc}
  ${GqlTokenPriceFragmentFragmentDoc}
`;

/**
 * __useGetAppGlobalDataQuery__
 *
 * To run a query within a React component, call `useGetAppGlobalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppGlobalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppGlobalDataQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *   },
 * });
 */
export function useGetAppGlobalDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAppGlobalDataQuery, GetAppGlobalDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppGlobalDataQuery, GetAppGlobalDataQueryVariables>(
    GetAppGlobalDataDocument,
    options,
  );
}
export function useGetAppGlobalDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAppGlobalDataQuery, GetAppGlobalDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppGlobalDataQuery, GetAppGlobalDataQueryVariables>(
    GetAppGlobalDataDocument,
    options,
  );
}
export type GetAppGlobalDataQueryHookResult = ReturnType<typeof useGetAppGlobalDataQuery>;
export type GetAppGlobalDataLazyQueryHookResult = ReturnType<typeof useGetAppGlobalDataLazyQuery>;
export type GetAppGlobalDataQueryResult = Apollo.QueryResult<
  GetAppGlobalDataQuery,
  GetAppGlobalDataQueryVariables
>;
export const GetAppGlobalPollingDataDocument = gql`
  query GetAppGlobalPollingData($chainId: Int) {
    getTokenPrices(chainId: $chainId) {
      ...GqlTokenPriceFragment
    }
    protocolMetrics(chainId: $chainId) {
      ...ProtocolMetricsFragment
    }
  }
  ${GqlTokenPriceFragmentFragmentDoc}
  ${ProtocolMetricsFragmentFragmentDoc}
`;

/**
 * __useGetAppGlobalPollingDataQuery__
 *
 * To run a query within a React component, call `useGetAppGlobalPollingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppGlobalPollingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppGlobalPollingDataQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *   },
 * });
 */
export function useGetAppGlobalPollingDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAppGlobalPollingDataQuery,
    GetAppGlobalPollingDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppGlobalPollingDataQuery, GetAppGlobalPollingDataQueryVariables>(
    GetAppGlobalPollingDataDocument,
    options,
  );
}
export function useGetAppGlobalPollingDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppGlobalPollingDataQuery,
    GetAppGlobalPollingDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppGlobalPollingDataQuery, GetAppGlobalPollingDataQueryVariables>(
    GetAppGlobalPollingDataDocument,
    options,
  );
}
export type GetAppGlobalPollingDataQueryHookResult = ReturnType<
  typeof useGetAppGlobalPollingDataQuery
>;
export type GetAppGlobalPollingDataLazyQueryHookResult = ReturnType<
  typeof useGetAppGlobalPollingDataLazyQuery
>;
export type GetAppGlobalPollingDataQueryResult = Apollo.QueryResult<
  GetAppGlobalPollingDataQuery,
  GetAppGlobalPollingDataQueryVariables
>;
export const GetTokensDocument = gql`
  query GetTokens($chainId: Int) {
    tokens: getAllTokens(chainId: $chainId) {
      ...GqlTokenFragment
    }
  }
  ${GqlTokenFragmentFragmentDoc}
`;

/**
 * __useGetTokensQuery__
 *
 * To run a query within a React component, call `useGetTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokensQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *   },
 * });
 */
export function useGetTokensQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTokensQuery, GetTokensQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTokensQuery, GetTokensQueryVariables>(GetTokensDocument, options);
}
export function useGetTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTokensQuery, GetTokensQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTokensQuery, GetTokensQueryVariables>(GetTokensDocument, options);
}
export type GetTokensQueryHookResult = ReturnType<typeof useGetTokensQuery>;
export type GetTokensLazyQueryHookResult = ReturnType<typeof useGetTokensLazyQuery>;
export type GetTokensQueryResult = Apollo.QueryResult<GetTokensQuery, GetTokensQueryVariables>;
export const GetTokenPricesDocument = gql`
  query GetTokenPrices($chainId: Int!) {
    tokenPrices: getTokenPrices(chainId: $chainId) {
      ...GqlTokenPriceFragment
    }
  }
  ${GqlTokenPriceFragmentFragmentDoc}
`;

/**
 * __useGetTokenPricesQuery__
 *
 * To run a query within a React component, call `useGetTokenPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokenPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokenPricesQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *   },
 * });
 */
export function useGetTokenPricesQuery(
  baseOptions: Apollo.QueryHookOptions<GetTokenPricesQuery, GetTokenPricesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTokenPricesQuery, GetTokenPricesQueryVariables>(
    GetTokenPricesDocument,
    options,
  );
}
export function useGetTokenPricesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTokenPricesQuery, GetTokenPricesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTokenPricesQuery, GetTokenPricesQueryVariables>(
    GetTokenPricesDocument,
    options,
  );
}
export type GetTokenPricesQueryHookResult = ReturnType<typeof useGetTokenPricesQuery>;
export type GetTokenPricesLazyQueryHookResult = ReturnType<typeof useGetTokenPricesLazyQuery>;
export type GetTokenPricesQueryResult = Apollo.QueryResult<
  GetTokenPricesQuery,
  GetTokenPricesQueryVariables
>;
export const GetProtocolDataDocument = gql`
  query GetProtocolData($chainId: Int) {
    protocolData: protocolMetrics(chainId: $chainId) {
      ...ProtocolMetricsFragment
    }
    protocolTokenPrice: getProtocolTokenPrice(chainId: $chainId)
  }
  ${ProtocolMetricsFragmentFragmentDoc}
`;

/**
 * __useGetProtocolDataQuery__
 *
 * To run a query within a React component, call `useGetProtocolDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProtocolDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProtocolDataQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *   },
 * });
 */
export function useGetProtocolDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProtocolDataQuery, GetProtocolDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProtocolDataQuery, GetProtocolDataQueryVariables>(
    GetProtocolDataDocument,
    options,
  );
}
export function useGetProtocolDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProtocolDataQuery, GetProtocolDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProtocolDataQuery, GetProtocolDataQueryVariables>(
    GetProtocolDataDocument,
    options,
  );
}
export type GetProtocolDataQueryHookResult = ReturnType<typeof useGetProtocolDataQuery>;
export type GetProtocolDataLazyQueryHookResult = ReturnType<typeof useGetProtocolDataLazyQuery>;
export type GetProtocolDataQueryResult = Apollo.QueryResult<
  GetProtocolDataQuery,
  GetProtocolDataQueryVariables
>;
export const GetProtocolTokenPriceDocument = gql`
  query GetProtocolTokenPrice($chainId: Int) {
    protocolTokenPrice: getProtocolTokenPrice(chainId: $chainId)
  }
`;

/**
 * __useGetProtocolTokenPriceQuery__
 *
 * To run a query within a React component, call `useGetProtocolTokenPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProtocolTokenPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProtocolTokenPriceQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *   },
 * });
 */
export function useGetProtocolTokenPriceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProtocolTokenPriceQuery,
    GetProtocolTokenPriceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProtocolTokenPriceQuery, GetProtocolTokenPriceQueryVariables>(
    GetProtocolTokenPriceDocument,
    options,
  );
}
export function useGetProtocolTokenPriceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProtocolTokenPriceQuery,
    GetProtocolTokenPriceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProtocolTokenPriceQuery, GetProtocolTokenPriceQueryVariables>(
    GetProtocolTokenPriceDocument,
    options,
  );
}
export type GetProtocolTokenPriceQueryHookResult = ReturnType<typeof useGetProtocolTokenPriceQuery>;
export type GetProtocolTokenPriceLazyQueryHookResult = ReturnType<
  typeof useGetProtocolTokenPriceLazyQuery
>;
export type GetProtocolTokenPriceQueryResult = Apollo.QueryResult<
  GetProtocolTokenPriceQuery,
  GetProtocolTokenPriceQueryVariables
>;
export const GetAllTokensForChainDocument = gql`
  query GetAllTokensForChain($chainId: Int) {
    tokens: getAllTokensForChain(chainId: $chainId) {
      ...GqlTokenFragment
    }
  }
  ${GqlTokenFragmentFragmentDoc}
`;

/**
 * __useGetAllTokensForChainQuery__
 *
 * To run a query within a React component, call `useGetAllTokensForChainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTokensForChainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTokensForChainQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *   },
 * });
 */
export function useGetAllTokensForChainQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllTokensForChainQuery,
    GetAllTokensForChainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllTokensForChainQuery, GetAllTokensForChainQueryVariables>(
    GetAllTokensForChainDocument,
    options,
  );
}
export function useGetAllTokensForChainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllTokensForChainQuery,
    GetAllTokensForChainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllTokensForChainQuery, GetAllTokensForChainQueryVariables>(
    GetAllTokensForChainDocument,
    options,
  );
}
export type GetAllTokensForChainQueryHookResult = ReturnType<typeof useGetAllTokensForChainQuery>;
export type GetAllTokensForChainLazyQueryHookResult = ReturnType<
  typeof useGetAllTokensForChainLazyQuery
>;
export type GetAllTokensForChainQueryResult = Apollo.QueryResult<
  GetAllTokensForChainQuery,
  GetAllTokensForChainQueryVariables
>;
export const GetUserDataDocument = gql`
  query GetUserData($chainId: Int, $account: String) {
    staking: userGetStaking(chainId: $chainId, account: $account) {
      address
      rewardPerSecond
      totalDepositAmount
      totalDepositValueUSD
      depositFee
      apr
      depositToken {
        address
        symbol
        currentPrice {
          price
        }
      }
      rewardToken {
        address
        symbol
        currentPrice {
          price
        }
      }
      userData {
        poolAllowance
        stakedBalance
        stakedBalanceValue
        pendingRewardAmount
        pendingRewardValue
        depositTokenBalance
      }
    }
  }
`;

/**
 * __useGetUserDataQuery__
 *
 * To run a query within a React component, call `useGetUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDataQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      account: // value for 'account'
 *   },
 * });
 */
export function useGetUserDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserDataQuery, GetUserDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserDataQuery, GetUserDataQueryVariables>(GetUserDataDocument, options);
}
export function useGetUserDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserDataQuery, GetUserDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserDataQuery, GetUserDataQueryVariables>(
    GetUserDataDocument,
    options,
  );
}
export type GetUserDataQueryHookResult = ReturnType<typeof useGetUserDataQuery>;
export type GetUserDataLazyQueryHookResult = ReturnType<typeof useGetUserDataLazyQuery>;
export type GetUserDataQueryResult = Apollo.QueryResult<
  GetUserDataQuery,
  GetUserDataQueryVariables
>;
export const GetUserNftPositionsDocument = gql`
  query GetUserNFTPositions($chainId: Int, $account: String) {
    nftPositions: userGetBaseNFTPoolPositionsInfo(chainId: $chainId, account: $account) {
      tokenId
      totalMultiplier
      positionValueUsd
      amount
      amountWithMultiplier
      aprInfo {
        lockAprBoost
        totalApr
        yieldBoosterBoostApr
      }
      boostPoints
      totalMultiplier
      hasActiveLock
      id
      lockDuration
      lockEndDateLabel
      lockMultiplier
      pendingRewards {
        pendingRewards
        usdValue
        token {
          address
          name
          symbol
        }
      }
      positionValueUsd
      startLockTime
      nftPool {
        address
        id
        maxGlobalMultiplier
        maxLockDuration
        maxLockMultiplier
        maxBoostMultiplier
        v2Pool {
          address
          id
        }
      }
    }
  }
`;

/**
 * __useGetUserNftPositionsQuery__
 *
 * To run a query within a React component, call `useGetUserNftPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNftPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNftPositionsQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      account: // value for 'account'
 *   },
 * });
 */
export function useGetUserNftPositionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserNftPositionsQuery,
    GetUserNftPositionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserNftPositionsQuery, GetUserNftPositionsQueryVariables>(
    GetUserNftPositionsDocument,
    options,
  );
}
export function useGetUserNftPositionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserNftPositionsQuery,
    GetUserNftPositionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserNftPositionsQuery, GetUserNftPositionsQueryVariables>(
    GetUserNftPositionsDocument,
    options,
  );
}
export type GetUserNftPositionsQueryHookResult = ReturnType<typeof useGetUserNftPositionsQuery>;
export type GetUserNftPositionsLazyQueryHookResult = ReturnType<
  typeof useGetUserNftPositionsLazyQuery
>;
export type GetUserNftPositionsQueryResult = Apollo.QueryResult<
  GetUserNftPositionsQuery,
  GetUserNftPositionsQueryVariables
>;
export const GetUserV2WalletBalancesDocument = gql`
  query GetUserV2WalletBalances($chainId: Int, $account: String) {
    balances: getUserV2PoolWalletBalances(chainId: $chainId, account: $account) {
      totalValueUSD
      balances {
        balance
        usdValue
        v2Pool {
          address
          id
        }
      }
    }
  }
`;

/**
 * __useGetUserV2WalletBalancesQuery__
 *
 * To run a query within a React component, call `useGetUserV2WalletBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserV2WalletBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserV2WalletBalancesQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      account: // value for 'account'
 *   },
 * });
 */
export function useGetUserV2WalletBalancesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserV2WalletBalancesQuery,
    GetUserV2WalletBalancesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserV2WalletBalancesQuery, GetUserV2WalletBalancesQueryVariables>(
    GetUserV2WalletBalancesDocument,
    options,
  );
}
export function useGetUserV2WalletBalancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserV2WalletBalancesQuery,
    GetUserV2WalletBalancesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserV2WalletBalancesQuery, GetUserV2WalletBalancesQueryVariables>(
    GetUserV2WalletBalancesDocument,
    options,
  );
}
export type GetUserV2WalletBalancesQueryHookResult = ReturnType<
  typeof useGetUserV2WalletBalancesQuery
>;
export type GetUserV2WalletBalancesLazyQueryHookResult = ReturnType<
  typeof useGetUserV2WalletBalancesLazyQuery
>;
export type GetUserV2WalletBalancesQueryResult = Apollo.QueryResult<
  GetUserV2WalletBalancesQuery,
  GetUserV2WalletBalancesQueryVariables
>;
export const GetUserTransactionsHistoryDocument = gql`
  query GetUserTransactionsHistory($args: UserTransactionsInput!) {
    getUserTransactionsHistory(args: $args) {
      type
      poolType
      amount0
      amount1
      amountUSD
      timestamp
      tx
    }
  }
`;

/**
 * __useGetUserTransactionsHistoryQuery__
 *
 * To run a query within a React component, call `useGetUserTransactionsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTransactionsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTransactionsHistoryQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useGetUserTransactionsHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserTransactionsHistoryQuery,
    GetUserTransactionsHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserTransactionsHistoryQuery, GetUserTransactionsHistoryQueryVariables>(
    GetUserTransactionsHistoryDocument,
    options,
  );
}
export function useGetUserTransactionsHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserTransactionsHistoryQuery,
    GetUserTransactionsHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserTransactionsHistoryQuery,
    GetUserTransactionsHistoryQueryVariables
  >(GetUserTransactionsHistoryDocument, options);
}
export type GetUserTransactionsHistoryQueryHookResult = ReturnType<
  typeof useGetUserTransactionsHistoryQuery
>;
export type GetUserTransactionsHistoryLazyQueryHookResult = ReturnType<
  typeof useGetUserTransactionsHistoryLazyQuery
>;
export type GetUserTransactionsHistoryQueryResult = Apollo.QueryResult<
  GetUserTransactionsHistoryQuery,
  GetUserTransactionsHistoryQueryVariables
>;
