export const getChainId = () => {
  const chainDef = process.env.NEXT_PUBLIC_CHAIN_MAIN;
  switch (chainDef) {
    case 'MODE':
      return 34443;
    case 'BASE':
      return 8453;
    case 'OPTIMISM':
      return 10;
    default:
      throw new Error(`Unsupported chain: ${chainDef}`);
  }
};

export const getChainName = () => {
  const chainDef = process.env.NEXT_PUBLIC_CHAIN_MAIN;
  switch (chainDef) {
    case 'MODE':
      return 'SwapMode';
    case 'BASE':
      return 'BaseSwap';
    case 'OPTIMISM':
      return 'SuperSwap';
    default:
      throw new Error(`Unsupported chain: ${chainDef}`);
  }
};

export const getChain = () => {
  const chainDef = process.env.NEXT_PUBLIC_CHAIN_MAIN;
  switch (chainDef) {
    case 'MODE':
      return 'Mode';
    case 'BASE':
      return 'Base';
    case 'OPTIMISM':
      return 'Optimism';
    default:
      throw new Error(`Unsupported chain: ${chainDef}`);
  }
};
